<template>
  <b-modal
    title="Manage Notifications App"
    title-tag="h3"
    modal-class="modal-primary"
    body-class="p-0"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    scrollable
    ref="manage-notifications-app-modal"
    hide-footer
    @hidden="$emit('hidden')"
  >
    <b-card class="mb-0" style="border-radius: 0px !important">
      <div class="d-flex justify-content-end">
        <b-button
          variant="success"
          @click="openModalCreateEditNotificationApp(null)"
          >CREATE</b-button
        >
      </div>
    </b-card>
    <manage-notifications-app-list
      :key="counterToRefresh"
      @createEditNotificationApp="openModalCreateEditNotificationApp"
    />
    <manage-notifications-app-create-edit
      v-if="createEditNotificationsApp"
      :notification-app="selectedNotificationApp"
      @hidden="hideModalCreateEditNotificationApp()"
      @onSaveNotification="onSaveNotification()"
    />
  </b-modal>
</template>

<script>
import ManageNotificationsAppList from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/modal/ManageNotificationsAppList.vue";
import ManageNotificationsAppCreateEdit from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/modal/ManageNotificationsAppCreateEdit.vue";
import ModalMixin from "@/mixins/modal";
export default {
  components: {
    ManageNotificationsAppList,
    ManageNotificationsAppCreateEdit,
  },
  mixins: [ModalMixin],
  mounted() {
    this.toggleModal("manage-notifications-app-modal");
  },
  data() {
    return {
      createEditNotificationsApp: false,
      selectedNotificationApp: {
        title: null,
        body: null,
        id: null,
      },
      counterToRefresh: 0,
    };
  },
  methods: {
    openModalCreateEditNotificationApp(notificationApp) {
      this.selectedNotificationApp = notificationApp;
      this.createEditNotificationsApp = true;
    },
    hideModalCreateEditNotificationApp() {
      this.selectedNotificationApp = {
        title: null,
        body: null,
        id: null,
      };
      this.createEditNotificationsApp = false;
    },
    onSaveNotification() {
      this.hideModalCreateEditNotificationApp();
      this.counterToRefresh++;
    },
  },
};
</script>

<style>
</style>
<template>
  <b-modal
    ref="calls-and-sms-modal"
    :title="`${
      actionType === 1
        ? 'ADD CALL'
        : actionType === 2
        ? 'SEND SMS'
        : 'Add Conversation'
    }`"
    size="lg"
    modal-class="modal-primary"
    title-tag="h3"
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <ValidationObserver ref="form">
      <b-row cols="1">
        <b-col>
          <b-form-group>
            <b-input-group prepend="Client">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                {{ clientName }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col v-if="actionType === 3" style="text-align: center">
          <b-form-checkbox
            v-model="smsImage.switch"
            @change="switchChange()"
            name="check-button"
            switch
          >
            <p
              v-text="smsImage.switch ? 'Image Send On' : 'Image Send Off'"
              :class="smsImage.switch ? 'text-primary' : ''"
            ></p>
          </b-form-checkbox>
          <b-form-group>
            <div class="image-upload">
              <label for="file_input_1" class="label-image">
                <div
                  class="form-group"
                  :class="smsImage.switch ? 'cursor-pointer' : ''"
                >
                  <input
                    type="file"
                    id="file_input_1"
                    accept="image/*"
                    @change="getImage"
                    :disabled="!smsImage.switch"
                    hidden
                  />
                  <figure class="figure">
                    <img
                      class="image-message"
                      :src="imageShow"
                      :class="!smsImage.switch ? 'image-disabled' : ''"
                      :style="width_image"
                    />
                  </figure>
                </div>
              </label>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-else style="text-align: center" class="m-1">
          <feather-icon
            icon="PhoneIcon"
            size="100"
            class="text-success"
            style="transform: rotate(15deg)"
          />
          <feather-icon icon="PlusIcon" size="40" class="mb-4 text-success" />
        </b-col>

        <b-col v-if="actionType === 3">
          <div
            class="w-100"
            :class="
              isDarkSkin ? 'prepend-content-dark' : 'prepend-content-light'
            "
          >
            Content
          </div>
          <validation-provider
            v-slot="{ errors }"
            name="commentary"
            rules="required"
          >
            <quill-editor
              style="width: 100%"
              v-model="commentary"
              :class="errors[0] ? 'border-danger rounded' : ''"
              class="quill-br"
              :options="editorOption"
            />
          </validation-provider>
          <!-- <ValidationProvider
            v-slot="{ errors }"
            name="commentary"
            rules="required"
          >
            <b-form-group label="Commentary">
              <b-form-textarea
                v-model="commentary"
                :state="errors[0] ? false : null"
                autofocus
              />
            </b-form-group>
          </ValidationProvider> -->
        </b-col>
        <b-col v-else>
          <div
            class="w-100"
            :class="
              isDarkSkin ? 'prepend-content-dark' : 'prepend-content-light'
            "
          >
            Content
          </div>
          <validation-provider
            v-slot="{ errors }"
            name="commentary"
            rules="required"
          >
            <quill-editor
              style="width: 100%"
              :class="errors[0] ? 'border-danger rounded' : ''"
              class="quill-br"
              v-model="commentary"
              :options="editorOption"
            />
          </validation-provider>
          <!-- <b-form-group label="Commentary">
            <b-form-textarea v-model="commentary" />
          </b-form-group> -->
        </b-col>
        <b-col v-if="actionType !== 3">
          <b-form-group>
            <br />
            <b-form-checkbox v-model="checkedNotes"
              >Save as note</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>
    <template #modal-footer>
      <b-button variant="success" @click="addCeDigitalCallsAndSms">{{
        actionType == 1 ? "SUBMIT" : actionType == 2 ? "SEND" : "ADD"
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services/index";
import ClaimService_v2 from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import { mapGetters } from "vuex";

export default {
  components: {
    quillEditor,
  },
  name: "CallsAndSmsModal",
  props: {
    clientData: {
      type: Object,
      default: null,
    },
    actionType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      editorOption: {
        modules: { toolbar: true },
      },
      commentary: null,
      checkedNotes: true,
      smsImage: {
        img: "",
        switch: false,
      },
      viewImage: "/assets/images/icons/sms-img.png",
      width_image: "width:40%",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    imageShow() {
      return this.viewImage;
    },
    clientName() {
      return this.clientData.client_name == null
        ? this.clientData.client
        : this.clientData.client_name;
    },
  },
  mounted() {
    this.toggleModal("calls-and-sms-modal");
  },
  methods: {
    getImage(e) {
      let file = e.target.files[0];
      this.smsImage.img = file;
      this.uploadImage(file);
      this.width_image = "width:100%";
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async addCeDigitalCallsAndSms() {
      const result = await this.$refs.form.validate();
      if (result) {
        try {
          const swal = await this.showConfirmSwal();
          if (swal.isConfirmed) {
            this.addPreloader();
            let response;
            if (this.currentUser.modul_id == 18) {
              const params = {
                type: "CALL",
                user: this.currentUser.user_id,
                claimId: this.clientData.claim_id,
                message: this.commentary,
                accountId: this.clientData.account_id,
                checkedNote: this.checkedNotes,
              };
              response = await ClaimService_v2.saveCallRegister(params);
              this.$emit("refresh");
            } else {
              const params = {
                client_account_id: this.clientData.client_account_id,
                account: this.clientData.account,
                lead_name: this.clientData.client_name,
                mobile: this.clientData.mobile,
                commentary: this.commentary,
                type_fi: this.actionType,
                created_by: this.currentUser.user_id,
                checked_note: this.checkedNotes,
                image: this.smsImage.switch === true ? this.viewImage : "",
                name_doc:
                  this.smsImage.switch === true ? this.smsImage.img.name : "",
              };
              response = await CallsAndSmsService.addCeDigitalCallsAndSms(
                params
              );
            }
            if (response.status === 200) {
              this.closeModal();
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Call Made Successfully"
              );
              this.actionType !== 3
                ? this.$emit(
                    "onSmsCallNotificationAdd",
                    this.actionType,
                    this.clientData
                  )
                : this.$emit("onAddCommentary", this.clientData);
            }
            this.removePreloader();
          }
        } catch (e) {
          this.removePreloader();
          console.log(e);
        }
      }
    },
    switchChange() {
      if (!this.smsImage.switch) {
        this.viewImage = "/assets/images/icons/sms-img.png";
        this.width_image = "width:40%";
      }
    },
  },
};
</script>

<style scoped>
</style>

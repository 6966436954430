<template>
  <div class="payment-schedule">
    <b-card class="border">
      <div class="schedule-title d-flex justify-content-between mb-1">
        <div></div>
        <h1>PAYMENT SCHEDULE</h1>
        <div>
          <feather-icon
            icon="RefreshCwIcon"
            size="20"
            class="cursor-pointer mr-1"
            style="color: #fff"
            v-b-tooltip.hover="'Refresh'"
            @click="getPaymentScheduleByClient"
          />
          <feather-icon
            icon="ListIcon"
            size="20"
            class="cursor-pointer"
            style="color: #fff"
            v-b-tooltip.hover="'Tracking'"
            @click="showModalTracking = true"
            v-if="items.length > 0"
          />
        </div>
      </div>
      <b-table
        ref="listCharges"
        responsive
        :fields="fields"
        :items="items"
        show-empty
        sticky-header="50vh"
        :busy.sync="isBusy"
        class="font-small-3 text-justify blue-scrollbar payment-schedule-grid"
        hover
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(pay_period)="data">
          <span>{{ data.index + 1 }}</span>
        </template>
        <template #cell(monthly_payment)="data">
          <span>$ {{ data.item.monthly_payment }}</span>
        </template>
        <template #cell(due_date)="data">
          <span>{{ data.item.due_date | formatDate }}</span>
        </template>
        <template #cell(settlement_date_authorize)="data">
          <template
            v-for="(item, index) in JSON.parse(data.item.payment_details)"
          >
            <div :key="index">
              <span>{{ item.settlement_date_authorize | myGlobalDay }}</span>
            </div>
          </template>
        </template>
        <template #cell(amount_paid)="data">
          <template
            v-for="(item, index) in JSON.parse(data.item.payment_details)"
          >
            <div :key="index" v-if="item.amount_paid">
              <span class="text-success"> $ {{ item.amount_paid }} </span>
              <span class="ml-1">{{ item.status_transaction }}</span>
              <span class="ml-1">{{ item.type_transaction }}</span>
              <span class="ml-1" v-if="item.charge_back">charge back</span>
            </div>
            <div :key="index" v-if="item.status == 'Pending'">-</div>
            <div :key="index" v-if="item.status == 'Partially Paid'">
              <span class="text-danger"> $ {{ item.pending_amount }} </span>
              <span class="ml-1 text-danger">Pending</span>
              <span class="ml-1 text-danger">Pending</span>
            </div>
          </template>
        </template>
        <template #cell(status)="data">
          <template
            v-for="(item, index) in JSON.parse(data.item.payment_details)"
          >
            <div
              :key="index"
              v-if="index === JSON.parse(data.item.payment_details).length - 1"
            >
              <template v-if="item.status == 'Paid'">
                <b-badge pill variant="light-success">Paid</b-badge>
              </template>
              <template v-if="item.status == 'Pending'">
                <b-badge pill variant="light-warning">Pending</b-badge>
              </template>
              <template v-if="item.status == 'Partially Paid'">
                <b-badge pill variant="light-info">Partially Paid</b-badge>
              </template>
            </div>
          </template>
        </template>
      </b-table>
      <div v-if="items.length > 0" class="payment-detail">
        <b-row>
          <b-col cols="12" sm="12" md="3" lg="4">
            <b-input-group prepend="FEE" class="mt-1">
              <b-form-input readonly :value="'$ ' + items[0].fee_amount" />
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="4">
            <b-input-group prepend="TOTAL PAID" class="mt-1">
              <b-form-input
                readonly
                :value="'$ ' + items[0].total_amount_paid"
              />
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="12" md="5" lg="4">
            <b-input-group prepend="TOTAL PENDING" class="mt-1">
              <b-form-input
                readonly
                :class="items[0].total_amount_pending >= 0 ? '' : 'text-danger'"
                :value="
                  items[0].total_amount_pending >= 0
                    ? '$ ' + 0
                    : '$ ' + items[0].total_amount_pending
                "
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <PaymentScheduleTrackingModal
      v-if="showModalTracking"
      :clientAccountId="clientAccountId"
      @close="showModalTracking = false"
    />
  </div>
</template>
<script>
import fields from "@/views/commons/components/clients/components/paymentSchedule/data/fields.paymentSchedule.data.js";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import PaymentScheduleTrackingModal from "@/views/commons/components/clients/components/paymentSchedule/modals/PaymentScheduleTrackingModal.vue";
import moment from "moment";
export default {
  name: "PaymentSchedule",
  components: {
    PaymentScheduleTrackingModal,
  },
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "-";
      return moment(String(value)).format("MM/DD/YYYY");
    },
  },
  data() {
    return {
      fields: fields,
      items: [],
      isBusy: false,
      showModalTracking: false,
    };
  },
  mounted() {
    this.getPaymentScheduleByClient();
  },
  methods: {
    async getPaymentScheduleByClient() {
      try {
        this.isBusy = true;
        const { data } =
          await PaymentScheduleService.getPaymentScheduleByClient({
            clientAccountId: this.clientAccountId,
          });
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
<style lang="scss">
.payment-schedule {
  .card {
    background: linear-gradient(
      to bottom,
      rgba(0, 144, 231, 1) 7rem,
      transparent 7rem
    );

    .card-body {
      margin: 0 2.5rem;
      .schedule-title {
        h1 {
          font-size: 1.5rem;
          font-weight: bold;
          color: #fff;
        }
      }
      .payment-schedule-grid {
        border-radius: 0.5rem;
        table {
          tbody {
            background-color: #fff;
          }
        }
      }
      .payment-detail {
        .input-group {
          .input-group-text {
            font-weight: bold;
          }
          .form-control {
            opacity: 1 !important;
            background-color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.dark-layout .payment-schedule {
  .card {
    .card-body {
      .payment-schedule-grid {
        table {
          tbody {
            background-color: unset;
          }
        }
      }
      .payment-detail {
        .input-group {
          .form-control {
            background-color: unset;
          }
        }
      }
    }
  }
}
</style>

<template>
  <b-modal
    ref="reschedule-pending-payment-modal"
    title="SCHEDULE PENDING PAYMENT"
    body-class="p-0"
    hide-footer
    @hidden="closeModal"
  >
    <div>
      <b-card
        :header="clientData.client_name"
        header-tag="header"
        header-class="p-1 justify-content-center text-white m-1"
        :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
        no-body
        title="Title"
      >
        <template #footer>
          <div style="display: flex; flex-direction: column; align-items: end">
            <feather-icon
              id="schedule-list"
              class="text-warning cursor-pointer mr-1"
              icon="WatchIcon"
              size="25"
              @click="openScheduleListModal"
            />
            <b-tooltip
              :key="count"
              target="schedule-list"
              triggers="hover"
              placement="top"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <span
                  style="
                    font-weight: bolder;
                    text-align: center;
                    font-size: 13px;
                  "
                  class="text-warning"
                  >Schedule List</span
                >
                <!--                <div-->
                <!--                  v-for="(scheduleList, index) in scheduleData"-->
                <!--                  :key="index"-->
                <!--                >-->
                <!--                  <span style="font-size: 13px;">{{ scheduleList.client_name }} {{ scheduleList.date_event | myDate }} <span class="text-warning">{{ scheduleList.hour_event | myTime }}</span></span>-->
                <!--                </div>-->
              </div>
            </b-tooltip>
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center"
            class="my-2"
          >
            <img
              :src="scheduleIcon"
              alt="schedule-icon"
              style="width: 30%; height: 30%"
            />
          </div>
          <ValidationObserver ref="form">
            <b-row class="d-flex align-items-center">
              <b-col cols="5">
                <validation-provider
                  v-slot="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-form-group label="DATE" :state="errors[0] ? false : null">
                    <kendo-datepicker
                      v-model="date"
                      v-mask="'##/##/####'"
                      :format="'MM/dd/yyyy'"
                      :class="{ 'border-danger': errors[0] }"
                      class="
                        leads-datepicker
                        w-100
                        rounded
                        bg-transparent
                        k-picker-custom
                        picker-select-date
                      "
                      readonly
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="time"
                  rules="required"
                >
                  <b-form-group label="TIME" :state="errors[0] ? false : null">
                    <kendo-timepicker
                      v-model="time"
                      :format="'HH:mm'"
                      minutes-step="30"
                      class="w-100 rounded bg-transparent k-picker-custom"
                      style="height: 2.73rem"
                      :class="{ 'border-danger': errors[0] }"
                      placeholder="Select hour"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <b-form-checkbox
                  id="forMySelf"
                  v-model="forMySelf"
                  name="forMySelf"
                >
                  For Myself
                </b-form-checkbox>
              </b-col>
              <b-col cols="12" v-if="!forMySelf">
                <b-form-group label="Agent" label-for="appSeller">
                  <v-select
                    input-id="appSeller"
                    v-model="userId"
                    label="fullName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="agents"
                    :reduce="(val) => val.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  id="withRecurrency"
                  v-model="withRecurrency"
                  name="withRecurrency"
                  class="mb-1"
                >
                  With recurrency
                </b-form-checkbox>
                <b-form-group label="Recurrency Type" label-for="recurrencyOpt" v-if="withRecurrency">
                  <v-select
                    input-id="recurrencyOpt"
                    v-model="recurrencyId"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="recurrencyOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="commentary"
                  rules="required"
                >
                  <b-form-group label="COMMENTARY">
                    <b-textarea
                      v-model="commentaryScheduled"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </ValidationObserver>

          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: end;
              margin-top: 20px;
            "
          >
            <b-button variant="success" @click="reschedulePendingPayment"
              >SAVE</b-button
            >
          </div>
        </template>
      </b-card>
    </div>

    <!--    <template #modal-footer>-->
    <!--      <b-button-->
    <!--        variant="success"-->
    <!--        @click="reschedulePendingPayment"-->
    <!--      >SAVE</b-button>-->
    <!--    </template>-->
    <schedule-list
      v-if="scheduleListModal"
      @closeModal="closeScheduleListModal"
    />
  </b-modal>
</template>

<script>
import CalendarSchedule from "@/assets/images/icons/calendar-schedule.svg";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services/index";
import { mapGetters } from "vuex";
import ScheduleList from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/ScheduleListModal";
import vSelect from "vue-select";
export default {
  name: "ReschedulePendingPaymentModal",
  components: { ScheduleList, vSelect },
  props: {
    clientData: {
      type: Object,
      default: null,
    },
    rescheduleId:{
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      scheduleIcon: CalendarSchedule,
      date: null,
      time: null,
      commentaryScheduled: null,
      // typeReschedule: false,
      scheduleData: [],
      count: 0,
      scheduleListModal: false,
      userId: null,
      agents: [],
      forMySelf: true,
      withRecurrency: false,
      recurrencyOptions: [
        { text: "Disabled", value: 1 },
        { text: "Daily", value: 2 },
        { text: "Weekly", value: 3 },
        { text: "Monthly", value: 4 },
        { text: "Yearly", value: 5 },
      ],
      recurrencyId: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.getActiveFinancialAgents();
    if(this.rescheduleId){
      this.getReschedulePaymentById();
    }
  },
  mounted() {
    this.toggleModal("reschedule-pending-payment-modal");
  },
  methods: {
    async getActiveFinancialAgents() {
      try {
        const { data: agents } =
          await CallsAndSmsService.getActiveFinancialAgents();
        this.agents = agents;
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    closeScheduleListModal() {
      this.scheduleListModal = false;
    },
    async getReschedulePaymentById() {
      try {
        const { data: reschedulePayment } =
          await CallsAndSmsService.getRescheduleById(this.rescheduleId);
      } catch (error) {
        console.error(error);
      }
    },
    async reschedulePendingPayment() {
      let isApointmentValidate = false;
      this.scheduleData.forEach((e) => {
        if (
          this.date ===
            new Date(e.date_event).toLocaleDateString("en-US", {
              timeZone: "UTC",
            }) &&
          this.time + ":00" === e.hour_event
        ) {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            "You have already appointment schedule in this time!"
          );
          return (isApointmentValidate = true);
        }
      });
      const result = await this.$refs.form.validate();
      if (result && !isApointmentValidate) {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            const params = {
              client_account_id: this.clientData.client_account_id,
              date_event: this.date,
              hour_event: this.time,
              mobile: this.clientData.mobile,
              commentary: this.commentaryScheduled,
              contacted_by: this.forMySelf ? this.currentUser.user_id : this.userId,
              program_id: 3,
              module_id: this.moduleId,
              recurrency: this.withRecurrency ? this.recurrencyId : 1,
              created_by: this.currentUser.user_id
            };
            const response = await CallsAndSmsService.reschedulePendingPayment(
              params
            );
            if (response.status == 200) {
              if (response.data.length > 0) {
                this.showWarningSwal(
                  "Very important!",
                  "Another agent scheduled this client!"
                );
              }else{
                this.showSuccessSwal()
              }
              this.closeModal();
              this.$emit("updatePendingPaymentGrid",this.clientData.client_account_id);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    openScheduleListModal() {
      this.scheduleListModal = true;
    },
  },
  watch:{
    withRecurrency(){
      if(!this.withRecurrency){
        this.recurrencyId = 1;
      }
    }
  }
};
</script>

<style scoped>
.change-type-box {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  text-align: center;
  margin-left: 300px;
  border-radius: 2em;
  padding: 10px;
}
</style>

<template>
  <b-modal
    ref="schedule-list-modal"
    title="SCHEDULE LIST"
    modal-class="modal-primary"
    title-tag="h3"
    hide-footer
    size="xmd"
    @hidden="closeModal"
    scrollable
  >
    <schedule-list :date-month="dateMonth" :date-year="dateYear" :all-list="allList" />
  </b-modal>
</template>

<script>
export default {
  name: "ScheduleListModal",
  components: {
    ScheduleList: () => import("@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/ScheduleList.vue"),
   },
  props: {
    allList: {
      type: Boolean,
      default: false,
    },
    clientData: {
      type: Array,
      default: null,
    },
    dateMonth: {
      type: Number,
      default: null,
    },
    dateYear: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.toggleModal("schedule-list-modal");
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      card-class="mb-0 border-0 p-0"
      @reload="$refs.refNotificationsAppList.refresh()"
    >
      <template #table>
        <b-table
          ref="refNotificationsAppList"
          no-provider-filtering
          primary-key="id"
          :fields="fields"
          show-empty
          sticky-header="50vh"
          :busy.sync="isBusy"
          :items="getNotificationsApp"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              icon="Edit2Icon"
              class="text-warning mr-1 cursor-pointer"
              @click="$emit('createEditNotificationApp', data.item)"
            />
            <feather-icon
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              @click="deleteNotificationApp(data.item.id)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import NotificationsAppService from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/service/notifications.service";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: [
        {
          key: "title",
          label: "Title",
          class: "text-left",
        },
        {
          key: "body",
          label: "Body",
          class: "text-left",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      filters: [],
      isBusy: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      endPage: null,
      startPage: null,
      toPage: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Title...",
        model: "",
      },
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getNotificationsApp(ctx) {
      try {
        const params = {
          searchText: this.filterPrincipal.model,
          orderBy: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          page: ctx.currentPage,
          perPage: ctx.perPage,
        };
        const data = await NotificationsAppService.getNotificationsApp(params);
        let items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return items || [];
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
    async deleteNotificationApp(id) {
      try {
        const result = await this.showConfirmSwal(
          "Are you sure you want to delete this notification?"
        );
        if (!result.isConfirmed) return;
        await NotificationsAppService.deleteNotificationApp({
          id,
          user_id: this.currentUser.user_id,
        });
        this.$refs.refNotificationsAppList.refresh();
        this.showSuccessSwal("Notification deleted successfully");
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
    
  },
};
</script>

<style>
</style>
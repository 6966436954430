<template>
  <b-modal
    ref="modal-payment-schedule"
    size="xmd"
    @hidden="$emit('hidden')"
    hide-footer
    scrollable
  >
    <!-- TITLE MODAL -->
    <template #modal-title>
      <span class="font-weight-bolder"> PAYMENT SCHEDULE </span>
    </template>
    <!-- BODY MODAL -->
    <template #default>
      <b-row class="m-0 mb-1">
        <b-col cols="12" md="6">
          <span> CLIENT </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ paymentInformation.client_name }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ paymentInformation.account }}
            </p>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end mb-1"></div>
      <modal-process-payment
        v-if="stateProcessPayment"
        :payment-information="paymentInformation"
        :type-tab="paymentInformation.type_month"
        @close="stateProcessPayment = false"
        @hideModal="stateProcessPayment = false"
        @refreshTable="refreshTable"
        @onManualPay="refreshTableOnProcessPayment"
        @onOtherPay="refreshTableOnProcessPayment"
      />

      <payment-schedule-table
        :key="keyPaymentSchedule"
        :client-account-id="paymentInformation.client_account_id"
        :tab-index-selected="tabIndexSelected"
      >
        <template #payment>
          <b-button
            v-if="moduleId == 23"
            variant="primary"
            @click="stateProcessPayment = true"
          >
            <feather-icon icon="PlusIcon" size="15" />
            MONTHLY PAYMENT
          </b-button>
        </template>
      </payment-schedule-table>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import PaymentScheduleGrid from "@/views/commons/components/clients/components/paymentSchedule/PaymentScheduleGrid.vue";
import ModalProcessPayment from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalProcessPayment.vue";
import PaymentScheduleTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/PaymentScheduleTable.vue";

export default {
  components: {
    PaymentScheduleGrid,
    ModalProcessPayment,
    PaymentScheduleTable,
  },
  props: {
    paymentInformation: {
      type: Object,
    },
  },
  data() {
    return {
      paymentSchedule: {
        lead_name: "-",
        account: "-",
        total_amount_pending: 0,
      },
      stateProcessPayment: false,
      keyPaymentSchedule: Math.random(),
      tabIndexSelected: 1,
    };
  },
  computed: {
    ...mapGetters({
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("modal-payment-schedule");
  },
  methods: {
    refreshPaymentSchedule() {
      this.keyPaymentSchedule = Math.random();
      this.stateProcessPayment = false;
    },
    refreshTable() {
      this.refreshPaymentSchedule();
      this.$emit("refreshTable");
    },
    refreshTableOnProcessPayment(payment, paymentMethod) {
      this.$emit("onManualPay", payment, paymentMethod);
    },
  },
  watch: {
    RELOAD_TRANSACTIONS_STATUS() {
      this.refreshPaymentSchedule();
    },
  },
};
</script>
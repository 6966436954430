export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Month',
    model: null,
    options: [
      { id: 1, text: 'January' },
      { id: 2, text: 'February' },
      { id: 3, text: 'March' },
      { id: 4, text: 'April' },
      { id: 5, text: 'May' },
      { id: 6, text: 'June' },
      { id: 7, text: 'July' },
      { id: 8, text: 'August' },
      { id: 9, text: 'September' },
      { id: 10, text: 'October' },
      { id: 11, text: 'November' },
      { id: 12, text: 'December' },

    ],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'month',
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Year',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'year',
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Payment Type',
    model: null,
    options: [
      { id: 0, text: 'All' },
      { id: 1, text: 'Automatic' },
      { id: 2, text: 'Manual' },
      { id: 3, text: 'Others' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'payment_type',
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Payment Day',
    model: null,
    options: [
      { value: 0, label: 'All' },
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 20, label: '20' },
      { value: 25, label: '25' },
      { value: 30, label: '30' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
    visible: false,
    key: 'payment_day',
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Paid',
    model: null,
    options: [
      { id: 0, text: 'All' },
      { id: 1, text: 'YES' },
      { id: 3, text: 'PENDING' },
      { id: 2, text: 'NO' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'type_paid',
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Client Type',
    model: null,
    options: [
      { id: 1, text: 'Regular' },
      { id: 2, text: 'Digital' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 12,
    visible: true,
    key: 'client_type',
  },
  {
    type: 'datepicker',
    margin: true,
    label: 'LP From',
    placeholder: 'date',
    class: 'font-small-3',
    showLabel: true,
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    label: 'LP To',
    placeholder: 'date',
    class: 'font-small-3',
    showLabel: true,
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Initial Fee From',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'initial_fee_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Initial Fee To',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'initial_fee_to',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Balance From',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'balance_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Balance To',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'balance_to',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Monthly A. From',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'monthly_amount_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Monthly A. To',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'monthly_amount_to',
    inputType: 'number',
  },
  { // 14
    type: 'k-timer',
    margin: true,
    showLabel: true,
    label: 'Contact Schedule From',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'initial_fee_from',
    inputType: 'number',
  },
  { // 15
    type: 'k-timer',
    margin: true,
    showLabel: true,
    label: 'Contact Schedule To',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
    key: 'initial_fee_to',
    inputType: 'number',
  },
  { // 16
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'State',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'state',
    cols: 12,
    visible: true,
  },
];

export default [
  {
    key: "client_name",
    label: "Client Name",
  },
  {
    key: "payment_type",
    label: "Payment Type",
  },
  {
    key: "paid",
    label: "Paid",
  },

  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "balance",
    label: "Balance",
  },
  {
    key: "last_payment",
    label: "LP",
  },

  {
    key: "client_state",
    label: "St",
  },
  {
    key: "enroll_date",
    label: "Enroll Date",
  },
  {
    key: "actions",
    label: "SMS",
    thClass: "text-center",
  },
];

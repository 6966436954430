<template>
  <b-modal
    ref="modal-process-payment"
    title="PROCESS PAYMENT"
    size="lg"
    modal-class="modal-primary"
    @hidden="hideModal"
    :hide-footer="!paymentMethod ? true : false"
    title-tag="h5"
  >
    <b-container class="my-1">
      <b-row>
        <b-col cols="6">
          <div
            class="p-1 rounded w-75"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
          >
            <h4 class="font-weight-bolder">
              CLIENT ({{ paymentInformation.client_type }})
            </h4>
            <p class="m-0" style="font-size: 1.2rem">
              {{ paymentInformation.client_name }}
            </p>
            <p class="m-0" style="font-size: 1.2rem">
              {{ paymentInformation.account }}
            </p>
          </div>
        </b-col>

        <b-col cols="6" class="d-flex justify-content-end">
          <div class="p-1 rounded" :class="isDarkSkin ? 'bg-dark' : 'bg-light'">
            <h5 class="font-weight-bolder">AMOUNT</h5>
            <b-form-group
              label-class="font-bureau-style font-weight-normal color-gray-input-sm"
            >
              <validation-observer ref="fromAmount">
                <validation-provider v-slot="{ errors }" :rules="rule">
                  <b-input-group>
                    <money
                      v-model="ammount"
                      v-bind="maskMoney"
                      class="form-control input-form"
                      :disabled="!editAmount"
                    />
                    <b-input-group-append style="margin-bottom: 7px">
                      <template>
                        <b-button
                          v-if="!editAmount"
                          variant="outline-warning"
                          class="btn-icon"
                          @click="editAmount = !editAmount"
                          style="border: 1px solid #cd7d31 !important"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="cursor-pointer"
                          />
                        </b-button>
                        <b-button
                          v-else
                          variant="outline-danger"
                          class="btn-icon"
                          @click="refreshAmount()"
                          style="border: 1px solid #af3b40 !important"
                        >
                          <feather-icon icon="XIcon" class="cursor-pointer" />
                        </b-button>
                      </template>
                    </b-input-group-append>
                  </b-input-group>
                  <span v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</span>
                </validation-provider>
              </validation-observer>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div
      v-if="
        monthlyPayments.length > 0 &&
        G_PROGRAMS_IN_NEW_PAYMENT.includes(programId)
      "
    >
      <div
        class="border rounded p-1 mb-1 text-center"
        style="width: 18rem; margin: 0 0 0 auto"
      >
        <strong>PAID AT: </strong>
        <span class="ml-1 text-uppercase">{{ currentDatetime }}</span>
      </div>
      <b-table
        hover
        responsive
        :items="monthlyPayments"
        :fields="visibleFields"
      >
        <template #cell(date)="data">
          {{ convertDate(data.item.date) }}
        </template>
        <template #cell(pay_at)="data">
          {{ convertDate(data.item.pay_at) }}
        </template>
        <template #cell(amount)="data"> ${{ data.item.amount }} </template>
        <template #cell(status)="data">
          <b-badge class="text-uppercase" :variant="data.item.color">{{
            data.item.status
          }}</b-badge>
        </template>
      </b-table>
    </div>
    <b-container class="my-2 pl-2">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Method of payment" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-2"
              v-model="paymentMethod"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio value="manual">Manual</b-form-radio>
              <b-form-radio value="other">Other</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="9" v-if="paymentMethod == 'manual'">
          <b-form-group label="Charges">
            <tabler-icon
              v-if="paymentInformation.charges == 0"
              icon="CircleCheckIcon"
              class="cursor-pointer text-success"
              size="22"
              title="Approve Charge"
              @click="openApproveCharge(paymentInformation)"
            />
            <b-img
              v-else
              class="cursor-pointer"
              title="Approve Charge"
              :src="assetsImg + '/images/icons/dcharge.ico'"
              @click="openApproveCharge(paymentInformation)"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-container>

    <b-container class="mt-2 px-1" v-if="paymentMethod == 'manual'">
      <b-row>
        <b-col>
          <b-nav card-header pills class="m-0">
            <b-nav-item
              :active="!tabState"
              :link-classes="['px-3', bgTabsNavs]"
              @click="tabType(1)"
            >
              Actives
            </b-nav-item>
            <b-nav-item
              :active="tabState"
              :link-classes="['px-3', bgTabsNavs]"
              @click="tabType(2)"
            >
              Deleted
            </b-nav-item>

            <b-nav-item
              class="d-flex justify-content-end"
              style="width: 60%"
              :link-classes="['p-0', 'pr-1']"
            >
              <feather-icon
                @click="openCreateCreditCard"
                icon="PlusCircleIcon"
                class="text-success p-0 m-0 cursor-pointer"
                v-b-tooltip.hover.top="'Add card'"
                size="24"
                style="cursor: pointer"
              />
            </b-nav-item>
          </b-nav>

          <template>
            <b-card-body class="border-top-primary border-table-radius p-0">
              <div v-if="!tabState">
                <b-table
                  ref="refCards"
                  small
                  show-empty
                  :fields="tabFields"
                  :items="searchCards"
                  class="font-small-3 text-center"
                  sticky-header="50vh"
                  :busy.sync="isBusy"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading...</strong>
                    </div>
                  </template>
                  <template v-slot:cell(radio)="data">
                    <b-form-radio
                      v-model="card_id"
                      :disabled="data.item.count_declined >= 3"
                      :value="data.item.id"
                      :name="'card' + data.item.id"
                      @change="changeCard(data.item.type_card)"
                    />
                  </template>

                  <template v-slot:cell(card_holder_name)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.cardholdername }}
                    </p>
                  </template>
                  <template v-slot:cell(card_number)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                    </p>
                  </template>
                  <template v-slot:cell(type)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.type_card }}
                    </p>
                  </template>
                  <template v-slot:cell(mm)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.card_expi_month }}
                    </p>
                  </template>
                  <template v-slot:cell(yy)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.card_expi_year }}
                    </p>
                  </template>
                  <template v-slot:cell(cvc)="data">
                    <p class="mb-0 font-weight-bold">
                      {{
                        data.item.cardsecuritycode != null
                          ? data.item.cardsecuritycode.length == 3
                            ? "XX" + data.item.cardsecuritycode.substr(2)
                            : "XXX" + data.item.cardsecuritycode.substr(3)
                          : ""
                      }}
                    </p>
                  </template>
                  <template v-slot:cell(created_by)="data">
                    <p class="mb-0 font-weight-bold">
                      <span>{{ data.item.created_by }}</span>
                      <br />
                      <span>{{ data.item.created_at | myGlobalDay }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <feather-icon
                      size="16"
                      icon="TrashIcon"
                      class="cursor-pointer text-danger"
                      @click="openDeleteCreditCard(data.item.id)"
                    />
                  </template>
                </b-table>
              </div>
              <div v-else>
                <b-table
                  ref="refCards"
                  small
                  show-empty
                  :fields="tabFields"
                  :items="deletesearchCards"
                  class="font-small-3 text-center"
                  sticky-header="50vh"
                  :busy.sync="isBusy"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading...</strong>
                    </div>
                  </template>
                  <template v-slot:cell(radio)="data">
                    <b-form-radio
                      v-model="card_id"
                      :disabled="data.item.count_declined >= 2"
                      :value="data.item.id"
                      :name="'card' + data.item.id"
                      @change="changeCard(data.item.type_card)"
                    />
                  </template>

                  <template v-slot:cell(card_holder_name)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.cardholdername }}
                    </p>
                  </template>
                  <template v-slot:cell(card_number)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                    </p>
                  </template>
                  <template v-slot:cell(type)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.type_card }}
                    </p>
                  </template>
                  <template v-slot:cell(mm)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.card_expi_month }}
                    </p>
                  </template>
                  <template v-slot:cell(yy)="data">
                    <p class="mb-0 font-weight-bold">
                      {{ data.item.card_expi_year }}
                    </p>
                  </template>
                  <template v-slot:cell(cvc)="data">
                    <p class="mb-0 font-weight-bold">
                      {{
                        data.item.cardsecuritycode != null
                          ? data.item.cardsecuritycode.length == 3
                            ? "XX" + data.item.cardsecuritycode.substr(2)
                            : "XXX" + data.item.cardsecuritycode.substr(3)
                          : ""
                      }}
                    </p>
                  </template>
                  <template v-slot:cell(deleted_by)="data">
                    <p class="mb-0 font-weight-bold">
                      <span>{{ data.item.deleted_by }}</span>
                      <br />
                      <span>{{ data.item.deleted_at | myGlobalDay }}</span>
                    </p>
                  </template>
                </b-table>
              </div>
            </b-card-body>
          </template>
        </b-col>
      </b-row>
      <delete-credit-card
        v-if="deleteCreditOn"
        :card_id="card_id"
        :lead_id="paymentInformation.lead_id"
        @refresh="$refs['refCards'].refresh()"
        @close="closeDeleteCreditCard"
      />
      <create-credit-card
        v-if="createCreditOn"
        :lead="paymentInformation.lead_id"
        @reload="$refs['refCards'].refresh()"
        @close="closeCreateCreditCard"
      />
    </b-container>

    <b-container class="mt-2 px-1" v-else>
      <other-payment-options
        ref="otherPaymentOptions"
        :amount="parseFloat(ammount)"
        :idClientOrLead="
          paymentInformation.client_account_id
            ? paymentInformation.client_account_id
            : paymentInformation.lead_id
        "
        :isClient="paymentInformation.client_account_id ? true : false"
        :idProgram="paymentInformation.program_id"
        :idModule="moduleId"
        :idCurrentUser="currentUser.user_id"
        :modalityPayment="monthlyPayment"
        :paymentFinantial="this.typeTab"
        :monthlyPayments="monthlyPayments"
        @close="closeModal()"
      ></other-payment-options>
    </b-container>
    <template #modal-footer>
      <b-button
        v-if="paymentMethod == 'manual'"
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertMonthlyPayment"
        :disabled="isMultiplePayments"
      >
        <b-spinner v-if="spinnerOn" small />
        PAY
      </b-button>
      <b-button
        v-if="paymentMethod == 'other'"
        class="d-flex justify-content-center"
        variant="primary"
        @click="saveOtherPayment"
        :disabled="isMultiplePayments"
      >
        <b-spinner v-if="spinnerOn" small />
        SENT FOR REVIEW
      </b-button>
    </template>
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      @closeModalApprove="modalApproveSupervisorOn = false"
    />
    <!-- @approveSup="chargesDisabled({ id: itemId })" -->
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/fields.data.js";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import PendingPaymentsService from "../../../service/pending-payments.service";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import moment from "moment";
export default {
  components: {
    DeleteCreditCard,
    CreateCreditCard,
    DragAndDrop,
    ModalApproveSupervisor,
    OtherPaymentOptions,
  },
  props: {
    paymentInformation: {
      type: Object,
    },
    typeTab: {
      type: Number,
    },
  },
  data() {
    return {
      currentIndexRow: null,
      ammount: 0,
      spinnerOn: false,
      tabState: false,
      isBusy: false,
      fields: dataFields,
      type_card: "",
      cantcards: [],
      ownControl: false,
      card_id: null,
      deleteCreditOn: false,
      createCreditOn: false,
      program_min: 0,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      paymentMethod: "manual",
      paymentType: [
        {
          text: "Manual",
          value: "manual",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      client: { evidences: [], date: "", otherSpecify: "", transactionId: "" },
      editAmount: false,
      itemId: null,
      validateChargeOn: false,
      modalApproveSupervisorOn: false,
      typeApprove: null,
      balanceAmount: 0,
      // newAmount: this.paymentInformation.monthly_amount,

      monthlyPayment: 1,
      monthlyPayments: [],
      fieldsMonthlyPayments: [
        { key: "date", label: "Old Payment Date", visible: true },
        { key: "pay_at", label: "New Payment Date", visible: false },
        { key: "amount", label: "Amount", visible: true },
        { key: "status", label: "Status", visible: true },
      ],
      isMultiplePayments: false,
      isAdvancePayment: null,
      isPendingAmount: 0,
      currentDatetime: moment().format("MM/DD/YYYY hh:mm a"),
      totalAmountFeePending: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    tabFields() {
      if (!this.tabState) {
        return this.fields;
      } else {
        let fields = this.fields;
        fields = fields.filter(
          (item) =>
            item.key != "created_by" &&
            item.key != "actions" &&
            item.key != "radio"
        );
        fields.push({
          key: "deleted_by",
          sortable: false,
          label: "deleted by",
        });
        return fields;
      }
    },
    typeOfCommission() {
      switch (this.typeTab) {
        case 1: // 1 is declined PA in payments_types_finantial
          return 10; // 10 is AUTOMATIC PAYMENT DECLINED in ced_setting_commission_type
        case 4: // 1 is 1 MONTH in payments_types_finantial
          return 11; // 11 is 1 MONTH in ced_setting_commission_type
        case 5: // 5 is 2 MONTH in payments_types_finantial
          return 12; // 12 is 2 MONTHS in ced_setting_commission_type
        case 6: // 6 is 3 MONTH in payments_types_finantial
          return 13; // 13 is 3 MONTHS in ced_setting_commission_type
        default:
          return 0;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    rule() {
      return `validate-amount-multiple:${this.balance},${this.program_min},${this.isPendingAmount}`;
    },
    visibleFields() {
      return this.fieldsMonthlyPayments.filter((item) => item.visible);
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    balance() {
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        return this.totalAmountFeePending;
      }
      return Math.abs(this.paymentInformation.balance);
    },
  },
  async mounted() {
    this.balanceAmount = Math.abs(this.paymentInformation.balance);
    this.toggleModal("modal-process-payment");
    if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
      await this.getTotalAmountFee();
      await this.getLastPaymentDate();
      this.getDateNow();
    }
  },
  created() {
    this.ammount = this.paymentInformation.monthly_amount;
    this.program_min = this.paymentInformation.program_min;
    this.ownControl = true;
  },

  methods: {
    async getTotalAmountFee() {
      try {
        this.addPreloader();
        const params = {
          client_account_id: this.paymentInformation.client_account_id,
        };
        const { data } = await PaymentScheduleService.getTotalAmountFee(params);
        if (data.length === 0) return;
        this.totalAmountFeePending = data.total[0].pending;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getLastPaymentDate() {
      try {
        this.addPreloader();
        const { data } = await PaymentScheduleService.getLastPaymentDate({
          client_account_id: this.paymentInformation.client_account_id,
        });
        this.isAdvancePayment =
          data.length > 0 ? moment(data[0].due_date) : moment();
        this.isPendingAmount =
          data.length > 0 ? parseFloat(data[0].pending_amount) : 0;
        this.changeAmount(this.ammount);
      } catch (error) {
        this.showErrorSwal(error);
      }finally {
        this.removePreloader();
      }
    },
    refreshAmount() {
      this.editAmount = !this.editAmount;
      this.ammount = this.paymentInformation.monthly_amount;
    },
    hideModal() {
      this.$emit("hideModal");
    },
    tabType(type) {
      if (type == 1) {
        this.tabState = false;
      } else {
        this.tabState = true;
      }
    },
    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.paymentInformation.lead_id,
        });
        this.cantcards = data.data;
        return this.cantcards;
      } catch (error) {
        this.removePreloader();
      }
    },
    async deletesearchCards() {
      try {
        const data = await ClientsOptionsServices.deletesearchcards({
          id: this.paymentInformation.lead_id,
        });
        this.deletecantcards = data.data;
        return this.deletecantcards;
      } catch (error) {
        this.removePreloader();
      }
    },
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    changeCard(id) {
      this.type_card = id;
    },
    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },
    closeDeleteCreditCard() {
      this.deleteCreditOn = false;
    },
    // Handle response code 1
    handleResponseSuccess(description) {
      const preventCommonTableAutoRefresh = true;
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        this.isMultiplePayments || this.showSuccessSwal(description);
        this.$emit("onManualPay", this.paymentInformation, this.paymentMethod, preventCommonTableAutoRefresh);
        this.isMultiplePayments || this.hideModal();
        return;
      }
      this.showSuccessSwal(description);
      this.$emit("onManualPay", this.paymentInformation, this.paymentMethod, preventCommonTableAutoRefresh);
      this.hideModal();
    },

    async insertMonthlyPayment() {
      const validate = await this.$refs["fromAmount"].validate();
      if (validate) {
        try {
          this.addPreloader();
          let params = {
            amount: this.ammount,
            idcard: this.card_id,
            method_payment:
              this.method_payment == 0
                ? this.newType == 0
                  ? 4
                  : 0
                : this.types,
            idsession: this.currentUser.user_id,
            merchant: 1,
            transaction:
              this.method_payment == 0 ? this.transaction : this.specify,
            account: this.paymentInformation.account,
            date: this.date,
            programid: this.paymentInformation.program_id,
            typeaction: "0",
            modul: this.moduleId,
            payments_types_finantial: this.typeTab,
          };

          if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
            //verificar que el ultimo pago no sea mayor al monto minimo por programa (minAmount)
            const length = this.monthlyPayments.length;
            const lastPayment = this.monthlyPayments[length - 1];
            const havePendingAmount = this.isPendingAmount;
            if (
              (havePendingAmount == 0 &&
                parseFloat(lastPayment.amount) < parseFloat(this.program_min) &&
                parseFloat(this.balance) !== parseFloat(this.ammount)) ||
              (havePendingAmount > 0 &&
                length > 1 &&
                parseFloat(lastPayment.amount) < parseFloat(this.program_min) &&
                parseFloat(this.balance) !== parseFloat(this.ammount))
            ) {
              const date = lastPayment.date;
              const html = `The  amount for the month <b> ${moment(date).format(
                "MMMM-YYYY"
              )} </b> must be equal or greater than Minimal program Amount <b>$ ${
                this.program_min
              } </b>`;
              this.showWarningSwal("Warning", null, html);
              return;
            }

            this.isMultiplePayments = true;

            for (let i = 0; i < this.monthlyPayments.length; i++) {
              const payment = this.monthlyPayments[i];

              this.showToast(
                "info",
                "top-center",
                `Processing payment (${moment(payment.pay_at).format(
                  "MMMM-YYYY"
                )}) ${i + 1} of ${this.monthlyPayments.length}`,
                "CheckIcon",
                "Please wait"
              );

              params.amount = payment.amount;
              params.invoiceNumber =
                `INV${i}-` + Math.floor(Math.random() * Math.pow(10, 9));
              params.status = payment.status;
              params.dateMonthlyPayment = payment.date;
              params.pay_at = payment.pay_at;
              params.isOtherPayment = 0;

              if (i == this.monthlyPayments.length - 1) {
                this.isMultiplePayments = false;
              }

              const { data } = await ClientsOptionsServices.getCheckout(params);

              let responseCode;

              if (data.status == "error") {
                responseCode = data.response.responseCode;
              } else {
                responseCode = data.transaction.responseCode;
              }

              this.showGenericToast({
                position: "top-right",
                variant: data.info.variant,
                icon: data.info.icon,
                title: data.info.title,
                text: data.message,
              });
              if (responseCode == 1) {
                const preventCommonTableAutoRefresh = true;
                this.isMultiplePayments || this.$emit("refreshTable", preventCommonTableAutoRefresh);
                this.handleResponseSuccess(data.message);
              } else if (responseCode == 2 || responseCode == 3) {
                this.showErrorSwal("", data.message ?? "Transaction declined");
                this.$emit("refreshTable");
                this.hideModal();
                break;
              }
            }

            if (this.type_payment === 1) {
              this.ammount = 0;
              this.card_id = null;
              this.$refs.refCards.refresh();
            }
            return;
          }

          //si no se ecuentra en el array de programas nuevos se ejecuta el siguiente codigo
          const { data } = await ClientsOptionsServices.getCheckout(params);

          let responseCode;

          if (data.status == "error") {
            responseCode = data.response.responseCode;
          } else {
            responseCode = data.transaction.responseCode;
          }

          this.showGenericToast({
            position: "top-right",
            variant: data.info.variant,
            icon: data.info.icon,
            title: data.info.title,
            text: data.message,
          });

          if (responseCode == 1) {
            this.handleResponseSuccess(data.message);
          } else if (responseCode == 2 || responseCode == 3) {
            this.showErrorSwal("", data.message ?? "Transaction declined");
            this.hideModal();
          }

          if (this.type_payment === 1) {
            this.ammount = 0;
            this.card_id = null;
            this.$refs.refCards.refresh();
          }
        } catch (e) {
          this.$emit("refreshTable");
          this.hideModal();
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },
    closeModal() {
      this.toggleModal("modal-process-payment");
      this.$emit("close");
      this.$emit("refreshTable");
    },
    async saveOtherPayment() {
      try {
        this.addPreloader();
        await this.$refs.otherPaymentOptions.saveOtherPayment();
      } catch (error) {
        if (
          this.membership == 2 &&
          this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)
        ) {
          this.$emit("refreshTable");
          this.hideModal();
        }
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async insertOtherCollection() {
      const validate = await this.$refs[("fromOther", "fromAmount")].validate();
      if (validate) {
        try {
          this.addPreloader();
          const formData = new FormData();
          formData.append(
            "client_account_id",
            this.paymentInformation.client_account_id
          );
          formData.append("client_account", this.paymentInformation.account);
          formData.append("amount", this.ammount);
          formData.append("evidence", this.client.evidences[0]);
          formData.append("requested_by", this.currentUser.user_id);
          formData.append("date", this.client.date);
          formData.append("requested_by_full_name", this.currentUser.fullName);
          formData.append("payments_types_finantial", this.typeTab);
          formData.append("transaction_id", this.client.transactionId);
          const data = await PendingPaymentsService.insertOtherCollection(
            formData
          );

          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.$emit(
              "onOtherPay",
              this.paymentInformation,
              this.paymentMethod
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    //advance payment preview
    changeAmount(value) {
      const valueBalance = this.balance;
      this.monthlyPayments = [];
      if (
        !(
          (value <= valueBalance && value >= this.program_min) ||
          value == valueBalance ||
          (this.isPendingAmount > 0 &&
            value < this.program_min &&
            value >= this.isPendingAmount)
        )
      ) {
        this.isMultiplePayments = true;
        return;
      }
      this.isMultiplePayments = false;
      const Month = this.isAdvancePayment.clone();
      const CurrentMonth = moment();
      const multiple = this.paymentInformation.monthly_amount;
      const remainingAmount = value - this.isPendingAmount;
      let months = multiple > 0 ? Math.ceil(remainingAmount / multiple) : 0;
      this.isPendingAmount > 0 ? (months += 1) : ""; //verificar si tiene un pago parcial pendiente del mes anterior sumar 1 mes mas
      let amount = value;
      let verifyPayAt = false;
      const currentMonthPayAt = moment();

      for (let i = 0; i < months; i++) {
        const isFirstPayment = i == 0;
        const paymentDate = isFirstPayment
          ? Month.format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss")
          : Month.add(1, "M").format("YYYY-MM-DD") +
            " " +
            moment().format("HH:mm:ss");
        const pendingAmount = isFirstPayment
          ? this.isPendingAmount
          : Math.min(multiple, amount); //verificar si tiene un pago parcial pendiente del mes anterior
        const payment =
          this.isPendingAmount > 0 && value >= this.isPendingAmount
            ? pendingAmount
            : Math.min(multiple, amount);
        const payAt = isFirstPayment
          ? currentMonthPayAt.format("YYYY-MM-DD HH:mm:ss")
          : currentMonthPayAt.add(1, "M").format("YYYY-MM-DD HH:mm:ss");
        const isCurrentMonth =
          Month.format("MM-YY") === CurrentMonth.format("MM-YY");
        const isAdvancePayment = Month.isAfter(CurrentMonth, "month");
        const isLatePayment = Month.isBefore(CurrentMonth, "month");

        if (isFirstPayment && isLatePayment) {
          verifyPayAt = true;
        }
        this.fieldsMonthlyPayments[0].label =
          verifyPayAt == true ? "Old Payment Date" : "Payment Date";
        this.fieldsMonthlyPayments[1].visible =
          verifyPayAt == true ? true : false;

        this.monthlyPayments.push({
          date: paymentDate,
          pay_at: verifyPayAt == true ? payAt : paymentDate,
          amount: payment.toFixed(2),
          color: isCurrentMonth
            ? "light-primary"
            : isAdvancePayment
            ? "light-info"
            : "light-danger",
          status: isCurrentMonth
            ? "CURRENT PAYMENT"
            : isAdvancePayment
            ? "ADVANCE PAYMENT"
            : "LATE PAYMENT",
        });

        amount -= payment;
      }
    },
    async ApproveOrDeclineHeldTransaction(transaction) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        this.$emit("refreshTable");
        this.showSuccessSwal(data.message);
        this.hideModal();
      } catch (error) {
        this.showErrorSwal(error.data.response.message);
      } finally {
        this.removePreloader();
      }
    },

    async approveHeldTransaction(transaction) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
          merchant_id: 1,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        this.showGenericToast({
          position: "top-right",
          variant: data.variant,
          icon: data.icon,
          title: data.message,
          text: data.text,
        });
        this.$emit("refreshTable");
      } catch (error) {
        this.showErrorSwal(error.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
    convertDate(date) {
      return moment(date).format("MMMM-YYYY");
    },
    getDateNow() {
      setInterval(() => {
        this.currentDatetime = moment().format("MM/DD/YYYY hh:mm a");
      }, 1000);
    },
  },
  watch: {
    ammount(newVal) {
      if (newVal != null && this.isAdvancePayment != null) {
        this.changeAmount(newVal);
      }
    },
  },
};
</script>

<style scoped>
.font-bold {
  font-weight: bold;
  font-size: 1.2em;
}

.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #bbbbbe; */
  background-clip: padding-box;
  border: 1px solid #3c3c3f;
  border-radius: 0.357rem;
  width: 100%;
}
</style>

<template>
  <b-modal
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    title="'SMS History'"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close()"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>{{ 'SMS History' }}</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>

      </div>
    </template>

    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refSmsHistoryClient'].refresh()"
    >
      <template #table>
        <b-table
          ref="refSmsHistoryClient"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2 ">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center text-primary my-2">
              <strong>No existing data.</strong>
            </div>
          </template>
          <template #head(client_name)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(sent_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="align-middle text-center">
              {{ data.item.client_name }}
            </div>
          </template>
          <template #cell(commentary)="data">
            <div style="word-wrap: break-word; width: 400px !important">
              <p
                style="line-height: 1.8; font-size: 14px"
                v-html="data.item.commentary"
              />
            </div>
          </template>
          <template #cell(sent_at)="data">
            <div class="align-middle text-center">
              {{ data.item.sent_by }}
              <br/>
              {{ data.item.sent_at | myGlobalWithHour }}
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>

import ClientService from '@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.';

export default {
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: [
        {
          key: 'client_name',
          sortable: false,
          label: 'Client Name',
          visible: true,
        },
        {
          key: 'commentary',
          sortable: false,
          label: 'Commentary',
          visible: true,
        },
        {
          key: 'sent_at',
          sortable: false,
          label: 'Sent at',
          visible: true,
        },
      ],
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },

    };
  },

  computed: {},
  watch: {},
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.onControl = false;
      this.$emit('close');
    },
    async myProvider() {
      const params = {
        client_account_id: this.clientAccountId,
        page: this.paginate.currentPage,
        perPage: this.paginate.perPage,
      };

      const { data } = await ClientService.getSmsHistoryClient(params);
      const items = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return items || [];
    },
  },

};
</script>
      <style lang="scss" scoped>
      .text-money {
          font-weight: 500;

          &::before {
              content: "$";
          }
      }

      .header-modal {
          position: relative;
          padding: 20px 0 20px 15px;
          background: rgba(22, 84, 204, 1);
          width: 100%;
          font-size: 22px;
          display: flex;
          justify-content: space-betweeen;

          span {
              font-size: 1.6rem;
              font-weight: bold;
              margin-left: 8px;
              color: #FFFFFF;
          }

          .container-icons {
              background-color: #FFFFFF;
              padding: 2.7px 6.37px;
              border-radius: 0.357rem;
              width: 31px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 15px;
              right: 20px;
              transition: all 0.23s ease 0.1s;

              &--dark {
                  background-color: #17171a;
              }

              &:hover {
                  transform: translate(-7px, 5px);
                  cursor: pointer;
              }

          }
      }

      .charge__programs {
        width: 47px;
        height: 40px;
        background-color: #E9E9E9;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
      }
      </style>
    <style>
      .b-table-bottom-row {
        position: sticky;
        bottom: 0px;
        z-index: 1;
      }
    </style>

<template>
  <b-modal
    v-model="isActive"
    @hidden="close"
    size="xmd"
    title="Result tracking"
    title-tag="h2"
    hide-footer
  >
    <b-table
      :items="items"
      :fields="fields"
      table-class="text-nowrap text-center"
      show-empty
      small
    >
      <template #cell(created_at)="{ value }">
        {{ value | myGlobalDay }}
      </template>
    </b-table>
  </b-modal>
</template>
<script>
// Import Service
import DebtorClientsService from "../service/debtor-clients.service";

export default {
  props: {
    transactionId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.start();
  },
  data() {
    return {
      isActive: false,
      items: [],
      fields: [
        {
          key: "client_name",
          label: "Client name",
        },
        {
          key: "result",
          label: "Result",
        },
        {
          key: "client_name",
          label: "Client name",
        },
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "created_at",
          label: "Created at",
        },
      ],
    };
  },
  methods: {
    close() {
      this.isActive = false;
      this.$emit("close");
    },
    async start() {
      const data = await DebtorClientsService.resultTracking(
        this.transactionId
      );
      this.items = data;

      this.isActive = true;
    },
  },
};
</script>
<template>
  <div>
    <b-container class="m-0 w-100" fluid>
      <header-slot v-if="!fromPendingPayments" />
      <b-row>
        <b-col sm="12">
          <!-- formulario -->
          <b-card>
            <h2 v-if="!fromPendingPayments">
              <span class="font-weight-bold">New Notification</span>
            </h2>

            <!--:::::::::::::::ini filtrar all/group/client:::::::::::::::-->
            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="d-flex mt-2"
              >
                <span class="d-flex align-items-center"> Select: </span>
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  class="text-left mb-0 ml-2"
                >
                  <b-form-radio-group
                    id="btn-radios-2"
                    v-model="clientsSelect"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    size="md"
                    name="radio-btn-outline"
                    buttons
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--:::::::::::::::fin filtrar all/group/client:::::::::::::::-->

            <b-row>
              <b-col cols="12" class="text-center">
                <b-spinner v-if="spinner" variant="primary" />
              </b-col>
            </b-row>

            <!--:::::::::::::::Ini list group 2:::::::::::::::-->
            <div
              class="mt-2"
              :class="clientsSelect == 'groupclient' ? '' : 'd-none'"
            >
              <b-form-group label-for="tags-with-dropdown">
                <b-form-tags
                  id="tags-with-dropdown"
                  v-model="value3"
                  no-outer-focus
                  class="mb-0"
                >
                  <template v-slot="{ tags, disabled, addTag, removeTag }">
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag.account"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          :disabled="disabled"
                          variant="info"
                          @remove="removeTag(tag)"
                          >{{ tag.substr(10) }}</b-form-tag
                        >
                      </li>
                    </ul>

                    <b-dropdown
                      size="sm"
                      variant="outline-secondary"
                      block
                      menu-class="w-100 menu_dropdown"
                    >
                      <template #button-content>
                        <b-icon icon="tag-fill" /> Select clients
                      </template>
                      <b-dropdown-form @submit.stop.prevent="() => {}">
                        <b-form-group
                          label="Search Client"
                          label-for="tag-search-input"
                          label-cols-md="auto"
                          class="mb-0"
                          label-size="sm"
                          :description="searchDesc"
                          :disabled="disabled"
                        >
                          <b-form-input
                            id="tag-search-input"
                            v-model="search3"
                            type="search"
                            size="sm"
                            autocomplete="off"
                          />
                        </b-form-group>
                      </b-dropdown-form>
                      <b-dropdown-divider />
                      <b-dropdown-item-button
                        v-for="option in availableOptions3"
                        :key="option.account"
                        class="btn_dropdown"
                        @click="onOptionClick({ option, addTag })"
                      >
                        {{ option.account + " - " + option.name }}
                      </b-dropdown-item-button>
                      <b-dropdown-text v-if="availableOptions3.length === 0">
                        There are no tags available to select
                      </b-dropdown-text>
                    </b-dropdown>
                  </template>
                </b-form-tags>
              </b-form-group>
            </div>
            <!--:::::::::::::::Fin list group 2:::::::::::::::-->

            <div v-if="!fromPendingPayments">
              <b-form
                :class="clientsSelect == 'client' ? '' : 'd-none'"
                @submit.prevent="getClients()"
              >
                <b-input-group prepend="Client" class="mt-2">
                  <b-form-input v-model="searchClient" />
                  <b-input-group-append>
                    <b-button variant="info" rounded="right" type="submit"
                      >Search</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form>
              <br />
              <b-alert
                v-if="accountsSelected.length > 0"
                variant="secondary"
                class="py-1"
                :class="clientsSelect == 'all' ? 'd-none' : ''"
                show
              >
                <b-row class="mx-1">
                  <b-badge
                    v-for="(item, index) in accountsSelected"
                    :key="index"
                    variant="light-warning"
                  >
                    <!-- icon trash -->
                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger clickable"
                      @click="deleteAccounts(index)"
                    />

                    {{ item.name }}
                  </b-badge>
                </b-row>
              </b-alert>
              <div v-else>
                <p
                  :class="
                    clientsSelect == 'groupclient' || clientsSelect == 'client'
                      ? 'd-none'
                      : ''
                  "
                  style="color: red; font-weight: bold"
                >
                  * all users will receive this notification.
                </p>
              </div>
            </div>
            <div v-else class="mb-2">
              <b-input-group prepend="Client" class="mt-3">
                <b-form-input :value="clientSelected.name" disabled />
              </b-input-group>
            </div>

            <b-form :key="newForm">
              <ValidationObserver ref="formclient">
                <b-form-group
                  label="Section Page"
                  label-for="SectionPage"
                  description="Select the section page to show the notification."
                >
                  <b-form-select
                    id="SectionPage"
                    v-model="dynamicSectionPage"
                    :options="dynamicSectionPages"
                    required
                  />
                </b-form-group>
                <b-button
                  variant="info"
                  class="rounded mb-1"
                  @click="openNotificationsAppModal()"
                  >Manage Notifications</b-button
                >
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <b-form-group
                    id="input-group-1"
                    label="Notification Title:"
                    label-for="input-1"
                    description="Notification Title"
                  >
                    <b-form-select
                      v-model="notification.selected"
                      :options="notifications"
                      :state="errors[0] ? false : null"
                      required
                      @change="selectedNotification()"
                    />
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" rules="required">
                  <b-form-group
                    id="input-group-2"
                    label="Notification:"
                    label-for="input-2"
                  >
                    <b-form-textarea
                      id="input-4"
                      v-model="notification.content"
                      :state="errors[0] ? false : null"
                      rows="3"
                      required
                    />
                  </b-form-group>
                </ValidationProvider>
              </ValidationObserver>
              <div class="clearfix">
                <div class="float-left mt-4">
                  <b-form-checkbox v-model="saveAsNote">
                    Save as note
                  </b-form-checkbox>
                </div>
                <div class="float-right mt-4">
                  <b-button variant="success" @click="sendNotification">
                    <i class="fas fa-save" /> Submit
                  </b-button>
                  <b-button
                    variant="danger"
                    class="ml-1"
                    @click="resetNotification"
                  >
                    <i class="fa fa-times-circle" /> Reset
                  </b-button>
                </div>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- ::::: MODAL ALL LIST CLIENTS ::::: -->
    <b-modal
      v-model="modalAllClients"
      modal
      size="lg"
      title="List of Clients"
      hide-footer
      @hide="closeModalClients"
    >
      <b-container>
        <b-table
          show-empty
          striped
          hover
          :items="options3"
          :fields="fields"
          sticky-header="70vh"
        >
          <template #head(radio)>
            <b-form-checkbox
              v-model="checkAllClients"
              @input="selectAllAccounts()"
            />
          </template>

          <template #cell(account)="data">
            <span class="pl-1"> {{ data.item.account }} </span>
          </template>

          <template #cell(radio)="data">
            <div class="text-center ml-0">
              <b-form-checkbox
                :value="true"
                :checked="data.item.radio"
                :name="data.item.account"
                @change="pushAccounts(data.item)"
              />
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>

    <!-- ******** MODAL LIST CLIENTS ****** -->
    <b-modal
      v-model="modalClient"
      modal
      size="lg"
      title="List of Clients"
      hide-footer
    >
      <b-container>
        <b-table striped hover :items="clients" :fields="fields">
          <template #cell(radio)="data">
            <b-form-checkbox
              :value="true"
              :checked="data.item.radio"
              :name="data.item.account"
              @change="pushAccounts(data.item)"
            />
          </template>
        </b-table>
      </b-container>
    </b-modal>
    <!-- *************** MODAL ADD NOTIFICATION ***************** -->
    <b-modal
      v-model="notificationNew.modal"
      modal
      title="Add Notification"
      size="lg"
      hide-footer
    >
      <b-form :key="newForm">
        <ValidationObserver ref="newNotification">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <b-form-group
              id="input-group-1"
              label="Notification Title:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="notificationNew.title"
                :state="errors[0] ? false : null"
                required
              />
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" rules="required">
            <b-form-group
              id="input-group-2"
              label="Notification:"
              label-for="input-2"
            >
              <b-form-textarea
                id="input-4"
                v-model="notificationNew.body"
                :state="errors[0] ? false : null"
                rows="3"
                required
              />
            </b-form-group>
          </ValidationProvider>
        </ValidationObserver>
        <div class="text-right mt-4">
          <b-button
            v-if="!notificationNew.loading"
            variant="success"
            @click="insertNotification"
          >
            <i class="fas fa-save" /> Save
          </b-button>
          <b-button v-else variant="info" class="rounded" disabled>
            <b-spinner small type="grow" />
            Loading...
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <manage-notifications-app
      v-if="manageNotificationsApp"
      @hidden="hideManageNotificationsApp()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationAppService from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/service/notifications.service.js";
import ManageNotificationsApp from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/modal/ManageNotificationsApp.vue";

export default {
  components: {
    ManageNotificationsApp,
  },
  props: {
    fromPendingPayments: {
      type: Boolean,
      default: false,
    },
    clientSelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      saveAsNote: false,
      modalClient: false,
      searchClient: null,
      clients: [],
      fields: [
        {
          key: "name",
          label: "name",
        },
        {
          key: "account",
          label: "account",
        },
        {
          key: "radio",
          label: "",
          thClass: "text-center",
        },
      ],
      accountsSelected: [],
      notification: {
        title: "",
        content: "",
        image: "/images/logos/logo-ce.png",
        selected: null,
      },
      notificationNew: {
        modal: false,
        title: "",
        body: "",
        loading: false,
      },
      newForm: 0,
      notifications: [],

      options: [
        { text: "All", value: "all" },
        { text: "Group of clients", value: "groupclient" },
        { text: "Client", value: "client" },
      ],
      clientsSelect: "client",

      options3: [],
      search3: "",
      value3: [],

      modalAllClients: false,
      checkAllClients: null,
      spinner: false,
      manageNotificationsApp: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    criteria() {
      return this.search3.toLowerCase(); // .trim()
    },
    availableOptions3() {
      const { criteria } = this;
      const options3 = this.options3.filter(
        (opt) => this.value3.indexOf(`${opt.account} - ${opt.name}`) === -1
      );
      if (criteria) {
        return options3.filter(
          (opt) =>
            `${opt.account.toLowerCase()} - ${opt.name.toLowerCase()}`.indexOf(
              criteria
            ) > -1
        );
      }
      return options3;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions3.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
    dynamicSectionPages() {
      return [
        {
          text: "Home",
          value: this.currentUser.user_id === 1 ? "/home" : "/dashboard",
        },
        {
          text: "Payments",
          value: "/payments",
        },
        {
          text: "FAQ",
          value: "/faq",
        },
        {
          text: "My Files",
          value: "/files",
        },
        {
          text: "Contact",
          value: "/contact-us",
        },
      ];
    },
    dynamicSectionPage() {
      return this.currentUser.user_id === 1 ? "/home" : "/dashboard";
    },
  },

  watch: {
    clientsSelect(newVal, oldVal) {
      if (newVal == "groupclient") {
        this.accountsSelected = [];
      }
      if (newVal == "client" || newVal == "all") {
        this.value3 = [];
        this.accountsSelected = [];
      }
      if (newVal) {
        if (newVal == "all") {
          // this.modalAllClients = true;
          this.accountsSelected = [];
          // this.checkAllClients = false;
          // this.options3 =
          this.options3.map((item) => {
            this.accountsSelected.push({
              account: item.account,
              name: item.name,
            });
            // return {
            //  name: item.name,
            //  account: item.account,
            //  radio: false,
            // };
          });
        }
      }
    },
  },
  mounted() {
    this.sectionPages[0].value =
      this.currentUser.user_id === 1 ? "/home" : "/dashboard";
    this.sectionPage = this.currentUser.user_id === 1 ? "/home" : "/dashboard";
    if (this.fromPendingPayments) {
      // delete 'all' option from options array
      this.options.splice(0, 1);
    }
    this.getAllClients();
  },
  created() {
    this.getNotifications();
    if (this.fromPendingPayments) {
      this.accountsSelected.push(this.clientSelected);
    }
  },
  methods: {
    hideManageNotificationsApp() {
      this.getNotifications();
      this.manageNotificationsApp = false;
    },
    openNotificationsAppModal() {
      this.manageNotificationsApp = true;
    },
    resetNotification() {
      this.accountsSelected = [];
      this.clientsSelect = "client";
      this.value3 = [];
      this.notification = {
        title: "",
        content: "",
        image: "/images/logos/logo-ce.png",
      };
      this.newForm++;
    },
    async sendNotification() {
      let listClientsF = [];
      if (this.value3) {
        listClientsF = this.value3.map((item) => {
          const clientF = item.split(" - ");
          const accountF = clientF[0];
          const nameF = clientF[1];
          return {
            account: accountF,
            name: nameF,
          };
        });
      }
      const validation = await this.$refs.formclient.validate();
      if (!validation) return;
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        const params = {
          title: this.notification.title,
          body: this.notification.content,
          accounts:
            this.clientsSelect == "groupclient"
              ? listClientsF
              : this.accountsSelected,
          user_id: this.currentUser.user_id,
          additional_data: {
            routing_key: this.dynamicSectionPage,
          },
          save_as_note: this.saveAsNote,
        };
        this.addPreloader();
        await NotificationAppService.sendNotification(params);
        this.showSuccessSwal("Notification sent successfully");
        this.$emit("notification", this.notification);
        this.removePreloader();
        this.resetNotification();
      }
    },
    async getClients() {
      if (this.searchClient == null) return;

      this.addPreloader();
      const params = {
        search: this.searchClient,
        user_id: this.currentUser.user_id,
      };
      const data = await NotificationAppService.getClientsApp(params);
      this.accountsSelected.forEach((elementLocal) => {
        data.forEach((element) => {
          if (elementLocal.account == element.account) {
            element.radio = true;
          }
        });
      });
      this.clients = data;
      this.modalClient = true;
      this.searchClient = null;
      this.removePreloader();
    },
    pushAccounts(data) {
      data.radio = !data.radio;
      if (data.radio) {
        this.accountsSelected.push({
          account: data.account,
          name: data.name,
        });
      } else {
        this.accountsSelected.forEach((element, index) => {
          if (data.account == element.account) {
            this.accountsSelected.splice(index, 1);
          }
        });
      }
    },
    async deleteAccounts(index) {
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        this.accountsSelected.splice(index, 1);
      }
    },
    async insertNotification() {
      const validation = await this.$refs.newNotification.validate();
      if (!validation) return;

      this.notificationNew.loading = true;
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        const params = {
          title: this.notificationNew.title,
          body: this.notificationNew.body,
        };
        await NotificationAppService.insertNotification(params);
        this.getNotifications();
        this.notificationNew.loading = false;
        this.notificationNew.modal = false;
        this.notificationNew.title = "";
        this.notificationNew.body = "";
      }
    },
    async getNotifications() {
      const data = await NotificationAppService.getAppDefaultNotifications();
      this.notifications = data;
    },
    selectedNotification() {
      this.notifications.forEach((element) => {
        if (element.value == this.notification.selected) {
          this.notification.title = element.value;
          this.notification.content = element.body;
        }
      });
    },

    onOptionClick({ option, addTag }) {
      addTag(`${option.account} - ${option.name}`);
      this.search3 = "";
    },
    async getAllClients() {
      const params = {
        search: null,
        user_id: "",
      };
      this.spinner = true;
      const data = await NotificationAppService.getClientsApp(params);
      if (data) {
        this.spinner = false;
      }
      this.options3 = data;
    },
    openModalClients() {
      if (this.clientsSelect == "all") {
        // console.log("oks");
      }
    },
    closeModalClients() {
      this.clientsSelect = "";
    },
    selectAllAccounts() {
      this.options3 = this.options3.map((item) => {
        // Ini insert validate
        if (this.checkAllClients) {
          this.accountsSelected.push({
            account: item.account,
            name: item.name,
          });
        } else {
          this.accountsSelected.forEach((element, index) => {
            if (item.account == element.account) {
              this.accountsSelected.splice(index, 1);
            }
          });
        }
        // Fin insert validate

        // return
        return {
          name: item.name,
          account: item.account,
          radio: !item.radio,
        };
      });
    },
  },
};
</script>
<style>
.menu_dropdown {
  max-height: 200px;
  overflow-y: scroll;
}

.btn_dropdown button {
  width: 100% !important;
}
</style>

<template>
  <div>
    <div>{{ displayedText }}</div>
    <b-button
      v-if="!!text && text.length > 17"
      variant="primary"
      size="sm"
      class="text-nowrap"
      @click="showHide()"
      >{{ changeText ? "Show" : "Hide" }}</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      changeText: true,
    };
  },
  computed: {
    displayedText() {
      if (!this.text) return "";

      return this.changeText ? this.text.slice(0, 17) : this.text;
    },
  },
  methods: {
    showHide() {
      this.changeText = !this.changeText;
    },
  },
};
</script>

<template>
  <b-modal
    v-model="isActive"
    @hidden="close"
    size="xmd"
    title="Label tracking"
    title-tag="h2"
    hide-footer
  >
    <b-table
      :items="items"
      :fields="fields"
      table-class="text-nowrap text-center"
      show-empty
      small
    >
      <template #cell(label)="item">
        {{
          `${item.item.label} ${
            item.item.other != null ? "(" + item.item.other + ")" : ""
          }`
        }}
      </template>
      <template #cell(status)="{ value }">
        <b-badge v-if="value === 'approve'" variant="light-success">
          {{ value }}</b-badge
        >
        <b-badge
          v-else-if="value === 'approval requested'"
          variant="light-warning"
        >
          {{ value }}
        </b-badge>
        <b-badge v-else variant="light-danger">{{ value }}</b-badge>
      </template>
      <template #cell(created_at)="{ value }">
        {{ value | myGlobalDay }}
      </template>
    </b-table>
  </b-modal>
</template>
<script>
// Import Service
import DebtorClientsService from "../service/debtor-clients.service";

export default {
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.start();
  },
  data() {
    return {
      isActive: false,
      items: [],
      fields: [
        {
          key: "client_name",
          label: "Client name",
        },
        {
          key: "label",
          label: "Label",
        },
        {
          key: "client_name",
          label: "Client name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "observation",
          label: "Observations",
        },
        {
          key: "user_name",
          label: "User",
        },
        {
          key: "created_at",
          label: "create at",
        },
      ],
    };
  },
  methods: {
    close() {
      this.isActive = false;
      this.$emit("close");
    },
    async start() {
      console.log(this.accountId);
      const data = await DebtorClientsService.labelTracking(this.accountId);
      this.items = data;

      this.isActive = true;
    },
  },
};
</script>
<template>
  <b-modal
    ref="modal-balance"
    title="BALANCE"
    size="xl"
    modal-class="modal-primary"
    scrollable
    @hidden="hideModal"
    hide-footer
    title-tag="h2"
  >
    <div class="mt-1">
      <b-row>
        <b-col cols="4">
          <div
            class="p-1 rounded"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            :style="isDarkSkin ? 'color: #bbbbbe' : 'color: #bbbbbe'"
          >
            <h4 class="text-center">CLIENT</h4>
            <hr />
            <p class="m-0" style="font-size: 1.2rem">
              {{ paymentInformation.client_name }}
            </p>
            <p class="m-0" style="font-size: 1.2rem">
              {{ paymentInformation.account }}
            </p>
          </div>
        </b-col>
        <b-col cols="8" class="d-flex justify-content-end">
          <div
            class="p-1 rounded text-center"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
          >
            <h4>BALANCE</h4>
            <hr />
            <span style="font-size: 2rem" class="d-flex justify-content-center">
              <money
                v-model="paymentInformation.balance"
                v-bind="maskMoney"
                :class="[
                  isDarkSkin ? 'bg-dark' : 'bg-light',
                  paymentInformation.balance < 0
                    ? 'text-danger balance'
                    : 'text-success balance',
                ]"
                class="balance"
                disabled
            /></span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="mt-4">
      <b-row>
        <b-col cols="5">
          <div class="px-2">
            <h4 class="pb-1">FEES AND CHARGES</h4>
            <b-table
              ref="listCharges"
              small
              responsive
              :fields="fields1"
              :items="searchListCharges"
              show-empty
              sticky-header="50vh"
              :busy.sync="isBusy"
              class="font-small-3 text-justify blue-scrollbar"
              :head-row-variant="'bg-blue-f'"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.created_at | myGlobal }}
                </p>
              </template>
              <template v-slot:cell(fee_or_charge)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.fee }}
                </p>
              </template>
              <template v-slot:cell(amount)="data">
                <div class="d-flex align-items-center">
                  <span style="width: 70px" class="mb-0 font-weight-bold">
                    $ {{ data.item.amount }}
                  </span>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col cols="7">
          <div class="pl-5">
            <h4 class="pb-1">PAYMENTS</h4>
            <b-table
              ref="listPayments"
              small
              show-empty
              sticky-header="50vh"
              :busy.sync="isBusy"
              class="font-small-3 justify-content-start blue-scrollbar"
              :fields="fieldsTransaction"
              :items="searchListPayments"
              :head-row-variant="'bg-green-f'"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div class="d-flex">
                  <div class="mr-1">
                    <div class="d-flex">
                      <!-- quitar edicion de fecha -->
                      <!-- <div v-if="data.item.update_payment_date_t == 0">
                        <feather-icon
                          v-if="!data.item.editSettlementDate"
                          class="ml-1 cursor-pointer text-success"
                          icon="Edit3Icon"
                          size="20"
                          @click="editDate(data.item)"
                        />
                      </div>
                      <div v-else>
                        <feather-icon
                          class="ml-1 cursor-pointer"
                          icon="AlignRightIcon"
                          size="20"
                          @click="openTracking(data.item)"
                        />
                      </div> -->
                    </div>
                    <div v-if="data.item.editSettlementDate">
                      <validation-observer :ref="`reschedule${data.item.t_id}`">
                        <validation-provider
                          v-slot="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <kendo-datepicker
                            :id="`contactDate-${data.item.t_id}`"
                            v-model="data.item.newSettlementDate"
                            v-mask="'##/##/####'"
                            :min="minDate"
                            :format="'MM/dd/yyyy'"
                            class="leads-datepicker rounded bg-transparent k-picker-custom picker-select-date"
                            :class="{
                              'border-danger': errors[0],
                            }"
                          />

                          <feather-icon
                            class="ml-1 cursor-pointer text-success"
                            icon="CheckIcon"
                            size="20"
                            @click="saveDate(data.item)"
                          />
                          <feather-icon
                            @click="hiddenDate(data.item)"
                            class="ml-1 cursor-pointer text-danger"
                            icon="XIcon"
                            size="20"
                          />
                        </validation-provider>
                      </validation-observer>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <p style="width: 100px" class="mb-0 font-weight-bold mr-1">
                      {{ data.item.settlement_date | myGlobal }}
                    </p>
                  </div>
                  <feather-icon
                    v-if="
                      data.item.status_transaction_id != 1 &&
                      data.item.amount == 0 &&
                      data.item.type_transaction_id != 10 &&
                      data.item.type_transaction_id != 11 &&
                      (isChief ||
                        isSupervisor ||
                        isAssistantSupervisor ||
                        isCeo)
                    "
                    @click="deleteWithouPayment(data.item.t_id)"
                    class="text-danger cursor-pointer"
                    size="18"
                    icon="XCircleIcon"
                  />
                </div>
              </template>
              <template v-slot:cell(type)="data">
                <p
                  class="mb-0 font-weight-bold mr-1"
                  :style="
                    data.item.type_transaction_id == 14 &&
                    data.item.status_transaction_id != 1
                      ? 'color: red'
                      : ''
                  "
                >
                  {{ data.item.type }}
                </p>
              </template>
              <template v-slot:cell(transaction_id)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.transaction_id }}
                </p>
              </template>

              <template v-slot:cell(amount)="data">
                <span
                  v-if="
                    data.item.type_transaction_id !== 10 &&
                    data.item.type_transaction_id !== 11 &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== null &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== 5 &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== 6
                  "
                  >$ {{ data.item.amount }}</span
                >
                <span
                  v-if="
                    data.item.type_transaction_id === 10 ||
                    data.item.type_transaction_id === 11
                  "
                  >$ ({{ data.item.amount }})</span
                >
                <!--44 - 45-->
                <span
                  v-if="
                    (data.item.type_transaction_id === 15 &&
                      data.item.method_transaction_id === null &&
                      data.item.modality_transaction_id === null) ||
                    (data.item.type_transaction_id === 15 &&
                      data.item.method_transaction_id === null &&
                      data.item.modality_transaction_id === 5)
                  "
                  style="color: red"
                  >$ {{ "-" + data.item.amount }}</span
                >
                <span
                  v-if="
                    data.item.type_transaction_id === 15 &&
                    data.item.method_transaction_id === null &&
                    data.item.modality_transaction_id === 6
                  "
                  style="color: green"
                  >$ {{ "-" + data.item.amount }}</span
                >
              </template>
              <template v-slot:cell(method)="data">
                <div class="d-flex justify-content-start">
                  <span style="min-width: 100px" class="mb-0 font-weight-bold">
                    {{ data.item.method_payment }}
                  </span>
                  <!-- <feather-icon
                    v-if="
                      data.item.type_transaction_id != 10 &&
                      data.item.type_transaction_id != 11 &&
                      data.item.amount == 0 &&
                      (isChief ||
                        isSupervisor ||
                        isAssistantSupervisor ||
                        isCeo)
                    "
                    :id="`EyeIcon_1-${data.index}`"
                    v-b-tooltip.hover.bottom
                    class="text-primary cursor-pointer"
                    size="18"
                    icon="EyeIcon"
                    title="SHOW"
                    @click="openZeroPaymentTableModal(data.item.t_id)"
                  /> -->
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  :style="`color: ${data.item.color}; cursor: ${
                    data.item.status_id != 4 && data.item.status_id != 19
                      ? `auto`
                      : `pointer`
                  }`"
                >
                  <feather-icon
                    @click="ApproveOrDeclineHeldTransaction(data.item)"
                    v-if="data.item.status_id == 4 || data.item.status_id == 19"
                    icon="RefreshCcwIcon"
                    style="margin-right: 0.1rem"
                    size="15"
                  />
                  <span v-if="data.item.status_id == 19" class="text-warning">
                    Held for review</span
                  >
                  <span
                    v-else-if="
                      [6, 14].includes(data.item.type_transaction_id) &&
                      data.item.status_id == 3
                    "
                  >
                    Pending
                  </span>

                  <span v-else> {{ data.item.status }}</span>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-3">
      <b-col lg="4" class="mt-1 p-2">
        <b-input-group class="">
          <template #prepend>
            <b-input-group-text class="title_lable"
              >FEES AND CHARGES
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <money
            v-if="finishedLoading"
            id="input-1"
            v-model="totalFeeAndCharges"
            v-bind="maskMoney"
            class="form-control w-auto Cmoney"
            disabled
            :style="{
              opacity: '1',
            }"
          />
          <!-- color: client.total_charge < 0 ? 'red' : null, -->
        </b-input-group></b-col
      >
      <b-col lg="4" class="mt-1 p-2"
        ><b-input-group>
          <template #prepend>
            <b-input-group-text class="title_lable">
              PAYMENTS
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />

          <money
            v-if="finishedLoading"
            id="input-2"
            v-model="paymentInformation.total_payment"
            v-bind="maskMoney"
            class="form-control w-auto Cmoney"
            :class="paymentInformation.total_payment < 0 ? 'text-danger' : null"
            disabled
            :style="{
              opacity: '1',
            }"
          />
          <!-- color: client.total_payments < 0 ? 'red' : null, -->
        </b-input-group></b-col
      >
      <b-col lg="4" class="mt-1 p-2"
        ><b-input-group>
          <template #prepend>
            <b-input-group-text class="title_lable"
              >ACCOUNT BALANCE
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />

          <money
            v-if="finishedLoading"
            id="input-3"
            v-model="paymentInformation.balance"
            v-bind="maskMoney"
            class="form-control w-auto Cmoney"
            :class="paymentInformation.balance < 0 ? 'text-danger' : null"
            disabled
            :style="{
              opacity: '1',
            }"
          />
          <!-- color: client.balance < 0 ? 'red' : null, -->
        </b-input-group></b-col
      >
    </b-row>
    <tracking-settlement-date
      v-if="stateTrackingSettlementDate"
      :client-data="{ transaction_id: transactionId }"
      @hideModal="stateTrackingSettlementDate = false"
    />
    <zero-payment-modal
      v-if="zeroPaymentOn"
      :zero-payment-transaction-id="zeroPaymentTransactionId"
      @refresh="$refs.listPayments.refresh()"
      @close="closeZeroPaymentModal"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/fields1.data";
import dataFields2 from "@/views/commons/components/clients/dashboard/options/pay-module/fields2.data";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ChargesMadeService from "@/views/ce-digital/sub-modules/financial/view/charges-made/charges-mage.service";
import moment from "moment";
import TrackingSettlementDate from "@/views/ce-digital/sub-modules/financial/view/charges-made/components/modal/TrackingSettlementDate.vue";
import ZeroPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/ZeroPaymentModal.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
export default {
  components: {
    TrackingSettlementDate,
    ZeroPaymentModal,
  },
  props: {
    paymentInformation: {
      type: Object,
    },
    typeTab: {
      type: Number,
    },
  },
  data() {
    return {
      fields1: dataFields,
      fields2: dataFields2,
      isBusy: false,
      finishedLoading: true,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      totalFeeAndCharges: 0,
      stateTrackingSettlementDate: false,
      clientData: {},
      minDate: new Date(
        `${new Date().getFullYear()}, ${new Date().getMonth()}, 06`
      ),
      maxDate: new Date(
        `${new Date().getFullYear()}, ${
          new Date().getMonth() + 1
        }, ${new Date().getDate()}`
      ),
      transactionId: null,
      zeroPaymentTransactionId: null,
      zeroPaymentOn: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    fieldsTransaction() {
      return this.fields2;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("modal-balance");
  },
  methods: {
    async ApproveOrDeclineHeldTransaction(transaction) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);
        this.$refs.listPayments.refresh();
        this.showSuccessSwal(data.message);
      } catch (error) {
        this.showErrorSwal(error.data.response.message);
      } finally {
        this.removePreloader();
      }
    },
    hideModal() {
      this.$emit("close");
      this.$emit("hideModal");
    },
    editDate(item) {
      this.$set(item, "editSettlementDate", true);
    },
    openTracking(item) {
      this.transactionId = item.t_id;
      this.stateTrackingSettlementDate = true;
    },
    // openZeroPaymentTableModal(id) {
    //   this.zeroPaymentTransactionId = id;
    //   this.zeroPaymentOn = true;
    // },
    // closeZeroPaymentModal() {
    //   this.zeroPaymentOn = false;
    // },
    async saveDate(item) {
      const validate = await this.$refs[`reschedule${item.t_id}`].validate();
      if (validate) {
        try {
          let result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            let params = {
              user_id: this.currentUser.user_id,
              oldSettlementDate: item.settlement_date,
              newSettlementDate: item.newSettlementDate,
              transaction_id: item.t_id,
            };
            const { status } =
              await ChargesMadeService.updateSettlementDateTransaction(params);

            if (status == 200) {
              this.$set(item, "editSettlementDate", false);
              this.$refs.listPayments.refresh();
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Date updated successfully"
              );
            }
          }
        } catch (error) {
          console.log(error);
          `  `;
        }
      }
    },
    hiddenDate(item) {
      this.$set(item, "editSettlementDate", false);
      this.$set(item, "newSettlementDate", null);
    },
    async deleteWithouPayment(id) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (isConfirmed) {
          const data = await ClientsOptionsServices.deleteWithoutPayment({
            id_transaction: id,
          });
          if (data.status === 200) {
            this.$refs.listPayments.refresh();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async searchListCharges() {
      try {
        const params = {
          id: this.paymentInformation.client_account_id,
        };
        const data = await ClientsOptionsServices.getListCharges(params);
        this.totalFeeAndCharges = data.data;
        let listCharges = data.data;

        this.totalFeeAndCharges = this.totalFeeAndCharges.reduce(
          (accumulator, object) => {
            return accumulator + Number(object.amount);
          },
          0
        );

        listCharges.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return listCharges || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async searchListPayments() {
      try {
        const params = {
          id: this.paymentInformation.client_account_id,
        };
        const data = await ClientsOptionsServices.getListPayments(params);

        let listPayments = data.data;
        listPayments.map((item) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (item.amount != null) {
            const data = formatter.format(item.amount);
            item.amount = data.replace("$", "");
          }

          this.$set(item, "editSettlementDate", false);
          this.$set(item, "newSettlementDate", null);

          let oldMonth = moment(new Date()).month();
          let newMonth = moment(item.settlement_date).month();

          if (newMonth - oldMonth == 0) {
            this.$set(item, "stateDate", true);
          } else {
            this.$set(item, "stateDate", false);
          }
        });
        return listPayments || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>
<style scoped>
.font-bold {
  font-weight: bold;
  font-size: 1.5em;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  color: #bbb9c1;
  background: #f74f4f;
  border: 1px solid #f43d3d;
  border-radius: 0.357rem;
  width: 100%;
}
</style>
<style>
.table-bg-green-f {
  background: #42ad80 !important;
  color: aliceblue;
}
.table-bg-blue-f {
  background: #2d9ca5 !important;
  color: aliceblue;
}
.bg-dark {
  background: #17171a;
}
.balance {
  border: 0px solid transparent;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 90%;

  text-align: center;
}
</style>

<template>
  <div>
    <template v-if="G_PENDING_PAYMENTS_COUNTER.length > 0">
      <b-row>
        <b-col>
          <b-nav card-header pills class="m-0">
            <b-nav-item
              v-for="day in [5, 10, 15, 20, 25, MONTH_END]"
              :key="day"
              :to="{
                name: $route.meta.pendingPaymentDayTab,
                params: { day: day },
              }"
              exact
              exact-active-class="sub-tab-nav"
              :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
            >
              <span>{{ day }}</span>
              <div class="ml-2" v-if="getBadgeCount(day) > 0">
                <feather-icon
                  icon
                  :badge="getBadgeCount(day)"
                  badge-classes="badge-danger"
                />
              </div>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
      <router-view :key="$route.name" />
    </template>
    <template v-else>
      <b-row>
        <b-skeleton
          v-for="index in 6"
          :key="index"
          type="input"
          width="150px"
          class="d-inline-block mr-1 mb-2"
        />
        <b-skeleton-table
          :rows="5"
          :columns="8"
          :table-props="{ bordered: true, striped: true }"
        />
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      G_PENDING_PAYMENTS_MONTH: "FinancialStore/G_PENDING_PAYMENTS_MONTH",
      G_PENDING_PAYMENTS_YEAR: "FinancialStore/G_PENDING_PAYMENTS_YEAR",
      G_PENDING_PAYMENTS_COUNTER: "FinancialStore/G_PENDING_PAYMENTS_COUNTER",
    }),
    MONTH_END() {
      if (this.G_PENDING_PAYMENTS_MONTH === 2) {
        const year = this.G_PENDING_PAYMENTS_YEAR || new Date().getFullYear();
        const nextMonth = new Date(year, this.G_PENDING_PAYMENTS_MONTH, 1);
        nextMonth.setDate(0);
        return nextMonth.getDate();
      }
      return 30;
    },
  },
  methods: {
    getBadgeCount(day) {
      return this.G_PENDING_PAYMENTS_COUNTER[0][`day_${day}`];
    },
  },
};
</script>

import { amgApi } from '@/service/axios'
import { ca } from 'date-fns/locale'

class DashboardFinancial {
  async getDashboardFinancialInformation(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-information', params)
      return data
    } catch (error) {
      console.log('Error getDashboardFinancialInformation')
    }
  }

  async getDashboardFinancialInformationSupervisor(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-information-supervisor', params)
      return data
    } catch (error) {
      console.log('Error getDashboardFinancialInformationSupervisor')
    }
  }

  async getDashboardFinancialInformationSupervisorCounters(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-information-supervisor-counters', params)
      return data
    } catch (error) {
      console.log('Error getDashboardFinancialInformationSupervisorCounters')
    }
  }

  async getTrackingRecevaiblePayFinancial(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-tracking-recevaible-pay-financial', params)
      return data
    } catch (error) {
      console.log('Error getTrackingRecevaiblePayFinancial')
    }
  }
  async getDetailOfPayments(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-detail-of-payments', params)
      return data
    } catch (error) {
      console.log('Error getTrackingRecevaiblePayFinancial')
    }
  }
  async getDetailOfClients(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-detail-of-clients', params)
      return data
    } catch (error) {
      console.log('Error getTrackingRecevaiblePayFinancial')
    }
  }
  async getDashboardFinancialLabelDebtorReport(params){
    try{
      const data=await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-label-debtor-report',params)
      return data;
    }catch(error){
      console.log('Error getDashboardFinancialLabelDebtorReport')
    }
  }
  async getDashboardFinancialResultDebtorReport(params){
    try{
      const data = await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-result-debtor-report',params)
      return data;
    }catch(error){
      console.log('Error getDashboardFinancialResultDebtorReport')
    }
  }
  async getDashboardFinancialResultLabelDebtorReport(params){
    try{
      const data = await amgApi.post('credit-experts-digital/financial/get-dashboard-financial-result-label-debtor-report',params)
      return data;
    }catch(error){
      console.log('Error getDashboardFinancialResultLabelDebtorReport')
    }
  }

  async getSmsHistoryClient(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-sms-client-history', params);
      return data;
    } catch (error) {
      console.log('Error getSmsHistoryClient')
    }
  }
}
export default new DashboardFinancial()

<template>
  <b-modal
    ref="send-sms-modal"
    title="SEND SMS"
    size="lg"
    modal-class="modal-primary"
    title-tag="h3"
    :no-close-on-backdrop="true"
    @hidden="closeModal"
  >
    <b-form-group>
      <b-input-group prepend="Client">
        <div
          class="form-control h-auto bg-transparent border-secondary sms-container"
        >
          {{ clientData.client_name }}
        </div>
      </b-input-group>
    </b-form-group>
    <b-form-group class="mt-2" label="Add Quick SMS" label-cols-md="2">
      <v-select
        v-model="smsOptions.quick"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="S_SMS_QUICKS"
        @input="onSelectSms()"
      />
    </b-form-group>
    <ValidationObserver ref="form">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <b-form-group class="mt-2" label="Select Number" label-cols-md="2">
          <v-select
            v-model="smsOptions.number"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="number_format"
            :reduce="(val) => val.id"
            :options="numbers"
            :class="{ 'border border-danger': errors[0] }"
          >
            <template #selected-option="{ number_format, you }">
              <span>{{ number_format }} {{ you ? `(${you})` : "" }}</span>
            </template>

            <template #option="{ number_format, you }">
              <span>{{ number_format }} {{ you ? `(${you})` : "" }}</span>
            </template>
          </v-select>
        </b-form-group>
      </ValidationProvider>
      <b-row>
        <b-col>
          <validation-provider v-slot="{ errors }" name="date" rules="required">
            <b-form-group label="SMS content">
              <b-form-textarea
                v-model="smsContent"
                rows="10"
                :state="errors[0] ? false : null"
              />
              <template #description>
                <small tabindex="-1" class="form-text text-danger"
                  >Max: 1000 characters</small
                >
              </template>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <b-form-checkbox
            v-model="smsImage.switch"
            @change="switchChange()"
            name="check-button"
            switch
          >
            <p
              v-text="smsImage.switch ? 'Image Send On' : 'Image Send Off'"
              :class="smsImage.switch ? 'text-primary' : ''"
            ></p>
          </b-form-checkbox>
          <b-form-group>
            <div class="image-upload">
              <label for="file_input_1" class="label-image">
                <div
                  class="form-group"
                  :class="smsImage.switch ? 'cursor-pointer' : ''"
                >
                  <input
                    type="file"
                    id="file_input_1"
                    accept="image/*"
                    @change="getImage"
                    :disabled="!smsImage.switch"
                    hidden
                  />
                  <figure class="figure">
                    <img
                      class="image-message"
                      :src="imageShow"
                      :class="!smsImage.switch ? 'image-disabled' : ''"
                    />
                  </figure>
                </div>
              </label>

              <!-- INPUT_FILE FIN -->
            </div>
          </b-form-group>
          <!-- Save as Note -->
          <!--          <b-form-checkbox-->
          <!--              v-model="savenote"-->
          <!--              name="check-button"-->
          <!--              switch-->
          <!--          > <span>Save as Note</span>-->
          <!--          </b-form-checkbox>-->
        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col lg="12">
          <b-form-group label="VARS" class="w-100">
            <b-row>
              <b-col sm="4">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text> @1 </b-input-group-prepend>
                  <b-form-input placeholder="FIRST NAME" readonly />
                </b-input-group>
              </b-col>
              <b-col sm="4">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text> @2 </b-input-group-prepend>
                  <b-form-input placeholder="LAST NAME" readonly />
                </b-input-group>
              </b-col>
              <b-col sm="4">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text> @3 </b-input-group-prepend>
                  <b-form-input placeholder="SENDER NAME" readonly />
                </b-input-group>
              </b-col>
              <b-col sm="4" class="mt-2">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text> @4 </b-input-group-prepend>
                  <b-form-input placeholder="PROGRAM NUMBER" readonly />
                </b-input-group>
              </b-col>
              <b-col sm="4" class="mt-2">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text> @5 </b-input-group-prepend>
                  <b-form-input placeholder="PROGRAM NAME" readonly />
                </b-input-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>

    <b-row cols="1">
      <b-col>
        <b-form-group label="Commentary">
          <b-form-textarea v-model="commentary" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-checkbox v-model="checkedNotes">Save as note</b-form-checkbox>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button variant="success" @click="sendSms">SEND</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CallsAndSmsService from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services";
import VSelect from "vue-select";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import RingcentralService from "@/service/commons/ringcentral.service.js";

export default {
  components: {
    VSelect,
  },
  props: {
    clientData: {
      type: Object,
      default: null,
    },
    actionType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      commentary: null,
      smsContent: "",
      smsOptions: {
        number: null,
        quick: null,
      },
      numbers: [],
      checkedNotes: true,
      smsImage: {
        img: "",
        switch: false,
      },
      viewImage: "/assets/images/icons/sms-img.png",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    ...mapState({
      S_SMS_QUICKS: (state) => state.CrmSmsStore.S_SMS_QUICKS,
    }),
    imageShow() {
      return this.viewImage;
    },
    commentaryAndSmsContent() {
      return `
      SMS Content: ${
        this.smsContent ? this.smsContent : "No SMS send"
      } Commentary Content: ${
        this.commentary ? this.commentary : "No commentary send"
      }
      `;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("send-sms-modal");
  },
  async created() {
    await this.getAllQuicksSms();
    await this.getNumberModule();
  },
  methods: {
    ...mapActions({
      A_GET_SMS_QUICKS: "CrmSmsStore/A_GET_SMS_QUICKS",
    }),
    async getAllQuicksSms() {
      try {
        await this.A_GET_SMS_QUICKS({
          modul: this.moduleId,
        });
      } catch (error) {
        console.log("Something went wrong getAllQuicksSms:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getNumberModule() {
      try {
        let params = {
          user_id: this.currentUser.user_id,
        };
        const { data } = await RingcentralService.getCredentialsByModuleId(
          6,
          params
        );
        this.numbers = data;
        if (data && data.length > 0) {
          this.smsOptions.number = data[0].id;
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSelectSms() {
      const index = this.S_SMS_QUICKS.map((el) => el.id).indexOf(
        this.smsOptions.quick ? this.smsOptions.quick.id : null
      );
      if (index !== -1) {
        const format = this.S_SMS_QUICKS[index].sms
          ? this.S_SMS_QUICKS[index].sms.replace(/<br \/>/g, "\n")
          : "";
        this.smsContent = format;
      } else {
        this.smsContent = "";
      }
    },
    getImage(e) {
      let file = e.target.files[0];
      this.smsImage.img = file;
      this.uploadImage(file);
    },
    uploadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async sendSms() {
      const result = await this.$refs.form.validate();
      if (result) {
        try {
          const formData = new FormData();
          const swal = await this.showConfirmSwal();
          if (swal.isConfirmed) {
            this.addPreloader();
            formData.append(
              "client_account_id",
              this.clientData.client_account_id
            );
            formData.append("lead_name", this.clientData.client_name);
            formData.append("mobile", this.clientData.mobile);
            formData.append("commentary", this.commentaryAndSmsContent);
            formData.append("type_fi", this.actionType);
            formData.append("created_by", this.currentUser.user_id);
            formData.append("sms_content", this.smsContent);
            formData.append("checked_note", this.checkedNotes);
            formData.append("account", this.clientData.account);

            formData.append(
              "image",
              this.smsImage.switch === true ? this.smsImage.img : ""
            );
            formData.append("number_id", this.smsOptions.number);

            const response = await CallsAndSmsService.addCeDigitalCallsAndSms(
              formData
            );
            if (response.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.closeModal();
              this.$emit("onAddSms", this.clientData);
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          this.removePreloader();
         }
      }
    },
    switchChange() {
      if (!this.smsImage.switch) {
        this.viewImage = "/assets/images/icons/sms-img.png";
      }
    },
  },
};
</script>

<style scoped>
.image-message {
  max-width: 14rem !important;
  min-width: 14rem !important;
  max-height: 14rem !important;
}
.image-disabled {
  filter: grayscale(100%);
}
</style>

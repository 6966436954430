<template>
  <b-modal
    ref="manage-notifications-app-create-edit"
    :title="notificationNew.id ? 'Edit Notification' : 'Add Notification'"
    size="lg"
    @hidden="$emit('hidden')"
    @ok.prevent="saveNotification()"
    ok-title="Save"
  >
    <b-form>
      <ValidationObserver ref="newNotification">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group
            id="input-group-1"
            label="Notification Title:"
            label-for="input-1"
          >
            <b-form-input
              :state="errors[0] ? false : null"
              id="input-1"
              v-model="notificationNew.title"
              required
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group
            id="input-group-2"
            label="Notification:"
            label-for="input-2"
          >
            <b-form-textarea
              :state="errors[0] ? false : null"
              id="input-4"
              rows="3"
              required
              v-model="notificationNew.body"
            ></b-form-textarea>
          </b-form-group>
        </ValidationProvider>
      </ValidationObserver>
    </b-form>
  </b-modal>
</template>

<script>
import ModalMixin from "@/mixins/modal";
import NotificationsAppService from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/service/notifications.service";
export default {
  props: {
    notificationApp: {
      type: Object,
      default: () => ({
        title: null,
        body: null,
        id: null,
      }),
    },
  },
  mixins: [ModalMixin],
  mounted() {
    this.toggleModal("manage-notifications-app-create-edit");
    if (this.notificationApp?.id) {
      this.notificationNew = { ...this.notificationApp };
    }
  },
  data() {
    return {
      notificationNew: {
        title: null,
        body: null,
        id: null,
      },
    };
  },
  methods: {
    async saveNotification() {
      try {
        const validation = await this.$refs.newNotification.validate();
        if (!validation) return;
        const result = await this.showConfirmSwal(
          "Are you sure you want to save this notification?",
          null
        );
        if (result.isConfirmed) {
          this.addPreloader();
          const params = {
            title: this.notificationNew.title,
            body: this.notificationNew.body,
            notification_id: this.notificationNew?.id,
          };
          await NotificationsAppService.insertNotification(params);
          this.showSuccessSwal(
            `Notification has been ${
              this.notificationApp?.id ? "updated" : "created"
            } successfully`
          );
          this.$emit("onSaveNotification");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <b-modal
    v-model="onControl"
    title="Send Notification"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    hide-footer
    @hidden="close"
  >
    <notification-app
      :from-pending-payments="true"
      :client-selected="clientSelected"
      @notification="addNotificationTracking"
    />
  </b-modal>
</template>

<script>
import NotificationApp from '@/views/ce-digital/sub-modules/customer-service/views/notification-app/views/NotificationApp.vue'
import CallsAndSmsService from '@/views/ce-digital/sub-modules/financial/view/calls-and-sms/services'
import { mapGetters } from 'vuex'

export default {
  components: {
    NotificationApp,
  },
  props: {
    clientSelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async addNotificationTracking(notification) {
      const params = {
        client_account_id: this.clientSelected.client_account_id,
        lead_name: this.clientSelected.name,
        mobile: this.clientSelected.mobile,
        commentary: notification.content,
        type_fi: 4,
        created_by: this.currentUser.user_id,
        checked_note: false,
      }
      const response = await CallsAndSmsService.addCeDigitalCallsAndSms(params)
      // console.log(notification)
      if (response.status === 200) {
        this.$emit('onAddNotification', this.clientSelected)
        this.close()
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    title="ZERO PAYMENT"
    @hidden="closeModal"
  >
    <b-row>
      <b-col>
        <b-card
          footer-class="p-2"
          header="CLIENT"
          header-class="p-1"
          header-tag="header"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.client_name }}</div>
          </template>
        </b-card></b-col
      >
      <b-col
        ><b-card
          footer-class="p-2"
          header="PROGRAM"
          header-class="p-1"
          header-tag="header"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.program_name }}</div>
          </template>
        </b-card></b-col
      >
      <b-col>
        <b-card
          footer-class="p-2"
          header="AMOUNT"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <money
              id="input-2"
              v-model="ammount"
              v-bind="maskMoney"
              class="form-control"
              disabled
            />
          </template> </b-card
      ></b-col>
    </b-row>
    <validation-observer ref="form">
      <b-row>
        <b-col md="9">
          <validation-provider v-slot="{ errors }" name="DATE" rules="required">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="title_lable"
                  >DATE
                </b-input-group-text>
              </template>

              <kendo-datepicker
                id="date"
                v-model="date"
                :disabled="validateEdit"
                :min="minDate"
                :max="maxDate"
                :format="'MM/dd/yyyy'"
                class="input-form"
                name="date"
                :class="
                  errors[0]
                    ? 'w-auto rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                    : 'w-auto rounded bg-transparent k-picker-custom picker-select-date'
                "
              />
            </b-input-group> </validation-provider
        ></b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="comment"
            rules="required"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="title_lable"
                  >COMMENT
                </b-input-group-text>
              </template>

              <b-textarea
                v-model="content"
                :disabled="validateEdit"
                :state="errors[0] ? false : null"
              />
            </b-input-group> </validation-provider
        ></b-col>
      </b-row>
      <b-row v-if="zeroPaymentTransactionId" class="mt-1">
        <b-col>
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                class="title_lable d-flex justify-content-center"
                >DONE BY
              </b-input-group-text>
            </template>
            <b-skeleton
              v-if="!finishedLoading"
              class="space"
              style="width: 100% !important"
              type="input"
            />
            <span
              v-if="finishedLoading"
              style="'border-color:rgba(255, 255, 255, 0.4)'; "
              :style="
                isDarkSkin
                  ? 'background: #17171A;opacity: 0.3;color:white'
                  : 'background: #efefef'
              "
              class="form-control"
              >{{ data.user_name }} |
              {{ data.created_at | myGlobalDay() }}</span
            >
          </b-input-group></b-col
        >
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <div v-if="zeroPaymentTransactionId" />
      <div v-else>
        <b-button
          class="d-flex justify-content-center"
          variant="primary"
          @click="insertnoteswithoutPayment"
        >
          <b-spinner v-if="spinnerOn" small />
          SAVE
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapMutations } from "vuex";
import ZeroPaymentService from "@/views/bookeeping/views/zero-payment/service/zero-payment.service";

export default {
  props: {
    zeroPaymentTransactionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      finishedLoading: false,
      date: "",
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(2049, 11, 31, 24, 0, 0),
      minDate: new Date(),
      maxDate: new Date(2050, 9, 1),
      spinnerOn: false,
      content: "",
      ammount: 0,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      ownControl: false,
      data: [],
      validateEdit: false,
    };
  },
  async created() {
    this.ownControl = true;
    if (this.zeroPaymentTransactionId) {
      this.validateEdit = true;
      await this.getWithouPayment();
    }
    this.finishedLoading = true;
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getWithouPayment() {
      try {
        const response = await ClientsOptionsServices.getWithoutPayment({
          id_transaction: this.zeroPaymentTransactionId,
        });

        this.data = response[0];
        this.content = this.data.comment;

        this.date = this.data.settlement_date;

        return this.data;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    async insertnoteswithoutPayment() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const params = {
            idca: this.$route.params.idClient,
            iduser: this.currentUser.user_id,
            ammount: this.ammount,
            content: this.content,
            pzdate: this.date,
          };
          const data = await ClientsOptionsServices.savewithoupayment(params);
          if (data.status === 200) {
            this.$emit("refresh");
            this.showSuccessSwal();
            await this.refreshCountPending(8, "zero-payment-bookeeping",this.getStatusId());
            await this.refreshCountPending(6, "zero-payment-court-info",this.getStatusId());
            this.closeModal();
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
    },

    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),

    async refreshCountPending(modulo, routeName,status) {
      const params = {
        id_global: this.currentUser.user_id,
        id_status: status,
        id_modul: modulo, //Bookeeping
        id_role: this.currentUser.role_id
      };
      const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
      if (data.length >= 0) {
        const payload = {
          routeName: routeName,
          tag: data.length,
        };
        //   store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        await this.countPending(payload);
      }
    },

     getStatusId() {
      let role_id = this.currentUser.role_id;
      if (role_id == 1) {
        return 3;
      } else if (role_id == 2) {
        return 2;
      }
    },
  },
};
</script>

<style scoped>
.title_lable {
  width: 100px;
}
</style>
